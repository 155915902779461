import _ from 'lodash'

import {
  ORGANIZATION_CONFIG_CLEAR,
  ORGANIZATION_CONFIG_FETCH,
  ORGANIZATION_CONFIG_FETCH_FAILED,
  ORGANIZATION_CONFIG_FETCH_SUCCEEDED,
} from './actions'

const initialState = {
  data: {},
  fail: false,
  load: false,
  messageError: '',
}

export const organizationConfigReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANIZATION_CONFIG_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case ORGANIZATION_CONFIG_FETCH: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case ORGANIZATION_CONFIG_FETCH_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case ORGANIZATION_CONFIG_FETCH_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    default:
      return state
  }
}

export default organizationConfigReducer
