export const DATA_REQUESTED_CLEAR = 'DATA_REQUESTED_CLEAR'

export const DATA_SET_TERM = 'DATA_SET_TERM'
export const DATA_SET_TYPE = 'DATA_SET_TYPE'

export const POST_DATA = 'POST_DATA'
export const POST_DATA_FAILED = 'POST_DATA_FAILED'
export const POST_DATA_SUCCEEDED = 'POST_DATA_SUCCEEDED'

export const FETCH_DATA = 'FETCH_DATA'
export const FETCH_DATA_FAILED = 'FETCH_DATA_FAILED'
export const FETCH_DATA_SUCCEEDED = 'FETCH_DATA_SUCCEEDED'

export const PATCH_DATA = 'PATCH_DATA'
export const PATCH_DATA_FAILED = 'PATCH_DATA_FAILED'
export const PATCH_DATA_SUCCEEDED = 'PATCH_DATA_SUCCEEDED'

export const PATCH_CLIENT_DATA = 'PATCH_CLIENT_DATA'
export const PATCH_CLIENT_DATA_FAILED = 'PATCH_CLIENT_DATA_FAILED'
export const PATCH_CLIENT_DATA_SUCCEEDED = 'PATCH_CLIENT_DATA_SUCCEEDED'

export const DATA_UPLOAD_PHOTO = 'DATA_UPLOAD_PHOTO'
export const DATA_UPLOAD_PHOTO_FAILED = 'DATA_UPLOAD_PHOTO_FAILED'
export const DATA_UPLOAD_PHOTO_SUCCEEDED = 'DATA_UPLOAD_PHOTO_SUCCEEDED'

export const DATA_DELETE_PHOTO = 'DATA_DELETE_PHOTO'
export const DATA_DELETE_PHOTO_FAILED = 'DATA_DELETE_PHOTO_FAILED'
export const DATA_DELETE_PHOTO_SUCCEEDED = 'DATA_DELETE_PHOTO_SUCCEEDED'

export const SELECT_ITEM = 'SELECT_ITEM'
export const START_BACKGROUND_CHECK_DATA_STATUS = 'START_BACKGROUND_CHECK_DATA_STATUS'
export const STOP_BACKGROUND_CHECK_DATA_STATUS = 'STOP_BACKGROUND_CHECK_DATA_STATUS'

export const dataRequestedClear = () => ({
  type: DATA_REQUESTED_CLEAR,
})

export const setType = type => ({
  type: DATA_SET_TYPE,
  payload: type,
})

export const setTerm = term => ({
  type: DATA_SET_TERM,
  payload: term,
})

export const postData = () => ({
  type: POST_DATA,
})

export const fetchData = (token, type) => ({
  type: FETCH_DATA,
  payload: { token: token, type: type },
})

export const patchData = (params) => ({
  type: PATCH_DATA,
  payload: { params: params },
})

export const patchClientData = (params) => ({
  type: PATCH_CLIENT_DATA,
  payload: { params: params },
})

export const uploadPhoto = (imageUrl) => ({
  type: DATA_UPLOAD_PHOTO,
  payload: { imageUrl: imageUrl },
})

export const deletePhoto = (imageUrl) => ({
  type: DATA_DELETE_PHOTO,
  payload: { imageUrl: imageUrl },
})

export const selectItem = (token, term, type) => ({
  type: SELECT_ITEM,
  payload: { token: token, term: term, type: type},
})

export const checkServicesStatus = () => ({
  type: START_BACKGROUND_CHECK_DATA_STATUS,
})
