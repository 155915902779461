import _ from 'lodash'

import {
  LIST_USER_CLEAR,
  FETCH_LIST_USERS,
  FETCH_LIST_USERS_FAILED,
  FETCH_LIST_USERS_SUCCEEDED,
  SET_USER_HISTORY_SHOW,
  INCREMENT_REQUESTS_COUNT,
  UPDATE_USER,
} from './actions'

const initialState = {
  users: [],
  fail: false,
  load: false,
  messageError: '',
  userHistoryShow: {},
}

export const listUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_USER_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case FETCH_LIST_USERS: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case FETCH_LIST_USERS_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case FETCH_LIST_USERS_SUCCEEDED: {
      return {
        ...state,
        users: action.payload.list,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    case SET_USER_HISTORY_SHOW:{
      return {
        ...state,
        userHistoryShow: action.payload.user,
      }
    }

    case INCREMENT_REQUESTS_COUNT: {
      const newData = _.cloneDeep(state.users)
      newData[0].total_requests_count += 1
      const newUserShow = _.cloneDeep(state.userHistoryShow)
      newUserShow.total_requests_count += 1
      return {
        ...state,
        users: newData,
        userHistoryShow: newUserShow,
      }
    }

    case UPDATE_USER: {
      const newData = _.cloneDeep(state.users)
      const index = _.findIndex(newData, {user_id: action.payload.user_id})
      newData[index] = action.payload.user
      return {
        ...state,
        users: newData,
      }
    }

    default:
      return state
  }
}

export default listUserReducer
