import React from 'react'

import {
  Button,
  Card,
} from '@material-ui/core'

import { TitleNormal, TitleBold } from 'components/styledComponents'
import typeData from 'helpers/constants/typeData'

import styles from './styles'

export default ({ consultType, consultValue, onClickClear }) => {
  const classes = styles()

  const handleClickClear = () => {
    if (onClickClear) onClickClear()
  }

  return (
    <Card className={classes.paper} style={{ marginBottom: 20 }}>
      <div className={classes.rowContainer}>
        <div style={{ display: 'flex', 'justifyContent': 'space-between' }}>
          <div>
            <TitleBold>{consultValue}</TitleBold>
            <TitleNormal>
              {
                consultType === typeData.licensePlate
                ? 'Placa'
                : 'Chassi'
              }
            </TitleNormal>
          </div>

          <div>
            <Button
              variant="contained"
              size="small"
              className={classes.buttonClear}
              onClick={handleClickClear}
            >
              Limpar
            </Button>
          </div>

        </div>
      </div>
    </Card>
  )
}
