import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')
  const dataManuObj = _.get(dataObj, 'manufacturing_data')
  const dataOwnerHistory = _.get(dataObj, 'owner_history')

  const renderOwnerHistory = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>UF:</span>
          <span className={classes.value}>{_.get(data, 'uf')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Renavam:</span>
          <span className={classes.value}>{_.get(data, 'renavam')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Dono:</span>
          <span className={classes.value}>{_.get(data, 'owner')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Placa:</span>
          <span className={classes.value}>{_.get(data, 'license_plate')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>CPF/CNPJ:</span>
          <span className={classes.value}>{_.get(data, 'cpf_cnpj')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Chassi:</span>
          <span className={classes.value}>{_.get(data, 'chassi')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Ano do exercício:</span>
          <span className={classes.value}>{_.get(data, 'tax_year')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Dados do Veiculo</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Proprietario:</span>
          <span className={classes.value}>{_.get(dataObj, 'owner_name')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Placa:</span>
          <span className={classes.value}>{_.get(dataObj, 'license_plate')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Chassi:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'chassi')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Renavam:</span>
          <span className={classes.value}>{_.get(dataObj, 'renavam')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>UF Placa:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'uf')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Cidade:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'city')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Marca/Modelo:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'brand_model')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Ano Fabricação:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'manufacture_year')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Ano Modelo:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'model_year')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Cor:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'color')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>N° Motor:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'engine_number')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Max Passageiros:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'max_passengers')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Combustível:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'fuel')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Tipo:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'kind')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Nacionalidade:</span>
          <span className={classes.value}>{_.get(dataManuObj, 'nationality')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Situação:</span>
          <span className={classes.value}>{_.get(dataObj, 'situation')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <br />
      <h2>Antigos Donos</h2>

      {_.map(dataOwnerHistory, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderOwnerHistory(item)}
        </React.Fragment>
      ))}

    </div>
  )
}
