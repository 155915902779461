import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  buttonClear: {
    fontSize: 18,
    color: '#000',
    border: 'none',
  },
  paper: {
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default styles
