export const ORGANIZATION_CONFIG_CLEAR = 'ORGANIZATION_CONFIG_CLEAR'

export const ORGANIZATION_CONFIG_FETCH = 'ORGANIZATION_CONFIG_FETCH'
export const ORGANIZATION_CONFIG_FETCH_FAILED = 'ORGANIZATION_CONFIG_FETCH_FAILED'
export const ORGANIZATION_CONFIG_FETCH_SUCCEEDED = 'ORGANIZATION_CONFIG_FETCH_SUCCEEDED'

export const organizationConfigClear = () => ({
  type: ORGANIZATION_CONFIG_CLEAR,
})

export const fetchOrganizationConfig = (organizationId) => ({
  type: ORGANIZATION_CONFIG_FETCH,
  payload: { organizationId: organizationId },
})
