/* eslint-disable no-console */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import * as Sentry from '@sentry/browser'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'
import { toast } from 'react-toastify'
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DialogContentText,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'

// import TemplateConfig from 'components/TemplateConfig'
import Report from 'components/Report'
import DialogConfirm from 'components/DialogConfirm'

import {
  fetchListUsers,
  listUsersClear,
  updateUserData,
} from 'redux/listUsers/actions'

import { fetchUserConfig, setCurrentPage, attUserConfig } from 'redux/userConfig/actions'
import { fetchOrganizationConfig } from 'redux/organizationConfig/actions'
import { isNetworkError, verifyToken } from 'helpers/functions'
import typeDataConstants from 'helpers/constants/typeData'
import { blockUser, updateUser } from 'services/apiMain'

import { TitleBold } from 'components/styledComponents'
import styles from './styles'

const AdmPanel = (props) => {
  const classes = styles(props)
  const dispatch = useDispatch()
  const history = useHistory()
  const listUsers = useSelector(state => state.listUsers)
  const userLogged = useSelector(state => state.userConfig.data)
  const organizationConfigData = useSelector(state => state.organizationConfig.data)
  const currentPage = useSelector(state => state.userConfig.data.currentPage)
  const accessToken = useSelector(state => state.auth.access_token)
  const sub = useSelector(state => state.auth.sub)
  const refreshtoken = useSelector(state => state.auth.refresh_token)
  const apiKey = useSelector(state => state.auth.api_key)

  // eslint-disable-next-line no-unused-vars
  const [newUser, setNewUser] = React.useState(false)
  const [listUser, setListUser] = React.useState(true)
  const [editUser, setEditUser] = React.useState(false)
  const [report, setReport] = React.useState(false)
  const [userSelected, setUserSelected] = React.useState({})
  const [openModalRemove, setOpenModalRemove] = React.useState(false)
  const [openEditModal, setOpenEditModal] = React.useState(false)
  const [openBlockModal, setOpenBlockModal] = React.useState(false)
  const [role, setRole] = React.useState(typeDataConstants.ROLE.COMMON)
  const [optionSelected, setOptionSelected] = React.useState('initialOption')
  const [templateConfigUser, setTemplateConfigUser] = React.useState(null)

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = React.useState(false)

  const [name, setName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [requestsLimit, setRequestsLimit] = React.useState(10)

  useEffect(() => {
    if (userLogged.role === typeDataConstants.ROLE.COMMON) {
      history.push('/')
      return
    }

    dispatch(listUsersClear())

    if(userLogged.role === typeDataConstants.ROLE.ADMIN){
      verifyToken(accessToken, history, dispatch, sub, refreshtoken)
      dispatch(fetchOrganizationConfig())
      dispatch(fetchListUsers())
    }

    if (currentPage !== 'adm-panel') dispatch(setCurrentPage('adm-panel'))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sub, accessToken, refreshtoken, userLogged.role, currentPage])

  const verifyFields = () => {
    if(_.isEmpty(name) || _.isEmpty(email) || _.isEmpty(requestsLimit) || requestsLimit < 10) return false
    return true
  }

  const clearFormData = () => {
    setName('')
    setEmail('')
    setRequestsLimit(10)
    setRole(typeDataConstants.ROLE.COMMON)
    setTemplateConfigUser(null)
  }

  const attUser = (type = null) => {
    if(type === 'block'){
      userSelected.blocked = !userSelected.blocked
    }else{
      userSelected.name = name
      userSelected.role = role
      userSelected.requests_limit = requestsLimit
      userSelected.template = templateConfigUser
    }

    return userSelected
  }

  const setDataForm = (userName, userRole, maxRequests, templateUser) => {
    setName(userName)
    setRole(userRole)
    setRequestsLimit(maxRequests)
    setTemplateConfigUser(templateUser)
  }

  // const handleNewUser = () => {
  //   setNewUser(true)
  //   setListUser(false)
  //   setReport(false)
  // }

  const showReport = () => {
    // setNewUser(false)
    setListUser(false)
    setReport(true)
    setUserSelected({})
    clearFormData()
  }

  const handleClose = () => {
    setOpenModalRemove(false)
    setOpenEditModal(false)
    setOpenBlockModal(false)
  }

  const handleConfirm = async (action) => {
    if(action === 'block'){
      await verifyToken(accessToken, history, dispatch, sub, refreshtoken)

      try {
        const res = await blockUser(apiKey, accessToken, userSelected.user_id, !userSelected.blocked)

        if (res.status === 200) {
          handleClose()
          const message = (userSelected.blocked) ? 'Usuário desbloqueado.' : 'Usuário bloqueado.'
          toast.success(message)
          dispatch(updateUserData(attUser('block')))
        }
      }
      catch(err){
        Sentry.captureException(err)
        const messageError = isNetworkError(err)
          ? `Problema na solicitação: ${err.message}`
          : `${_.get(err, 'response.data.msg_infos.0.msg')}`

        toast.error(messageError)
      }
    }
    // if(action === 'delete')
      // chamar a api para remover o usuário
      // await verifyToken()
    // else
      // block()

    //
  }

  const handleOpenModal = (user, action) => {
    setUserSelected(user)
    if (action === 'delete') setOpenModalRemove(true)
    else setOpenBlockModal(true)
  }

  const handleEditUser = (user) => {
    setUserSelected(user)
    clearFormData()
    setDataForm(user.name, user.role, user.requests_limit, user.template)
    setEditUser(true)
    setOpenEditModal(true)
  }

  const handleListUser = () => {
    // setNewUser(false)
    setListUser(true)
    setReport(false)
    setUserSelected({})
    clearFormData()
  }

  const handleSubmit = () => {
    if(verifyFields()){
      // chamar a api
      toast.success("Cadastro realizado com sucesso.")
      // dispatch(listUsersClear())
      // dispatch(fetchListUsers())
    }else{
      toast.error("Dados faltantes. Verifique os dados e tente novamente.")
    }
  }

  const handleChangeTemplateConfigUser = (e, type) => {
    setTemplateConfigUser({
      ...templateConfigUser,
      [type]: e.target.checked,
    })
  }

  const handleEdit = async () => {
    if(verifyFields()){
      toast.error("Valores inválidos para um ou mais campos.")
      return
    }

    if(
      role === userSelected.role
      && name === userSelected.name
      && requestsLimit === userSelected.requests_limit
      && _.isEqual(templateConfigUser, userSelected.template)
    ) {
      handleClose()
      toast.warning('Nenhuma alteração necessária.')
      return
    }

    try {
      await verifyToken(accessToken, history, dispatch, sub, refreshtoken)

      const res = await updateUser(
        apiKey,
        accessToken,
        {
          user_id: userSelected.user_id,
          role: role,
          name: name,
          requests_limit: requestsLimit,
          templateUser: templateConfigUser,
        })

      if(res.status === 200){
        handleClose()
        toast.success('Alteração realizada com sucesso.')
        dispatch(updateUserData(attUser()))
        if(userSelected.user_id === userLogged.user_id){
          dispatch(attUserConfig(attUser()))
          dispatch(fetchUserConfig())
        }

      }
    } catch(err) {
      Sentry.captureException(err)
      const messageError = isNetworkError(err)
        ? `Problema na solicitação: ${err.message}`
        : `${_.get(err, 'response.data.msg_infos.0.msg')}`

      toast.error(messageError)
    }
  }

  const handleSelectOrg = async (event) => {
    const organizationId = event.target.value

    if(organizationId === optionSelected){
      return
    }

    dispatch(listUsersClear())
    setOptionSelected(organizationId)

    if(organizationId === 'initialOption'){
      return
    }

    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    dispatch(fetchOrganizationConfig(organizationId))
    dispatch(fetchListUsers({ organization_id: organizationId }))
  }

  const renderMenu = () => {
    return (
      <List style={{marginTop: 10}}>
        <ListItem
          className={classes.listItems}
          button
          onClick={handleListUser}
          disable={listUser.toString()}
          selected={listUser}
        >
          <ListItemText>Listar Usuários</ListItemText>
        </ListItem>

        {/* <ListItem
          className={classes.listItems}
          button
          onClick={handleNewUser}
          disable={newUser.toString()}
          selected={newUser}
        >
          <ListItemText>Cadastrar Usuário</ListItemText>
        </ListItem> */}

        <ListItem
          className={classes.listItems}
          button
          onClick={showReport}
          disable={report.toString()}
          selected={report}
        >
          <ListItemText>Relatório</ListItemText>
        </ListItem>


        <ListItem
          className={classes.listItems}
          button
          onClick={() => history.push('/dashboard')}
        >
          <ListItemText>Voltar</ListItemText>
        </ListItem>
      </List>
    )
  }

  const renderList = () => {
    return (
      <React.Fragment>
        {listUsers.load && <CircularProgress style={{marginTop: 16, marginLeft: 16}} />}
        {listUsers.users.length > 0 && (
          <List style={{marginTop: 16, marginLeft: 10}}>
            {listUsers.users.map(user => (
              <Card key={user.user_id} className={classes.paper}>
                <ListItem>
                  <ListItemText>
                    <p className={classes.firstLineItem}>
                      <span className={classes.userName}>{`${user.name} (${user.role})`}</span>
                      <span className={classes.spanRequests}>
                        {user.total_requests_count}
                        {' '}
                        consultas
                        {' '}
                        <strong>
                          (máx.:
                          {' '}
                          {user.requests_limit}
                          )
                        </strong>
                      </span>
                    </p>
                    {listUser && (
                    <React.Fragment>
                      <p>
                        <span className={classes.userEmail}>{user.email}</span>
                      </p>
                      <p className={classes.buttonsParagraph}>
                        <span className={classes.lineButtons}>
                          {
                            [typeDataConstants.ROLE.MASTER, typeDataConstants.ROLE.ADMIN].includes(userLogged.role) &&
                            <button className={classes.buttonEdit} type="button" onClick={() => handleEditUser(user)}>Editar</button>
                          }
                          {(userLogged.user_id === user.user_id) ? null : (
                            /* <button className={classes.buttonRemove} type="button" onClick={() => handleOpenModal(user, 'delete')}>Excluir</button> */
                            <button className={classes.buttonRemove} type="button" onClick={() => handleOpenModal(user, 'block')}>
                              {(user.blocked)
                                      ? 'Desbloquear'
                                      : 'Bloquear'
                                    }
                            </button>
                              )}
                        </span>
                      </p>
                    </React.Fragment>
                      )}
                  </ListItemText>
                </ListItem>
              </Card>
              ))}
          </List>
            )
        }
        {(!listUsers.load && listUsers.users.length === 0 && optionSelected !== 'initialOption') && (
          <div className={classes.errorUsers}>
            A organização selecionada não possui nenhum usuário cadastrado!
          </div>
        )}
      </React.Fragment>
    )
  }

  // eslint-disable-next-line no-unused-vars
  const renderMasterList = () => {
    return (
      <Select
        className={classes.selectOrg}
        onChange={handleSelectOrg}
        value={optionSelected}
      >
        <MenuItem key="initialOption" value="initialOption">
          Selecione uma organização...
        </MenuItem>

        {(!userLogged.managed_organizations)
          ? <CircularProgress style={{marginTop: 16, marginLeft: 16}} />
          :
          userLogged.managed_organizations.map(item => {
            return (
              <MenuItem key={item.name} value={item.organization_id}>
                {item.name}
              </MenuItem>
            )
          },
          )
        }
      </Select>
    )
  }

  const renderRemoveModal = () => {
    return (
      <DialogConfirm
        open={openModalRemove}
        onConfirm={(e) => handleConfirm('delete')}
        onCancel={(e) => handleClose(e)}
        title="Confirmar Exclusão"
        content={`Deseja excluir o usuário ${userSelected.name} ?`}
      />
    )
  }

  const renderBlockModal = () => {
    return (
      <DialogConfirm
        open={openBlockModal}
        onConfirm={(e) => handleConfirm('block')}
        onCancel={(e) => handleClose(e)}
        title={`Confirmar ${userSelected.blocked ? 'Desbloqueio' : 'Bloqueio'}`}
        content={
          userSelected.blocked
          ? `Desbloquear o usuário ${userSelected.name} ?`
          : `Ao bloquear o usuário, você nega o acesso do mesmo ao sistema. Confirmar o bloqueio do usuário ${userSelected.name} ?`
        }
      />
    )
  }

  const renderEditModal = () => {
    return (
      <DialogConfirm
        open={openEditModal}
        onConfirm={(e) => handleEdit(e)}
        onCancel={(e) => handleClose(e)}
        title="Alterar Usuário"
        btnConfirmText="Alterar"
        content={(
          <React.Fragment>
            {[typeDataConstants.ROLE.MASTER].includes(userLogged.role) && (
              <TextField
                id="name"
                label="Nome"
                className={classes.textField}
                value={name}
                style={{width: '100%', marginLeft: 0}}
                onChange={(e) => setName(e.target.value)}
                required={true}
              />
            )}

            {[typeDataConstants.ROLE.MASTER].includes(userLogged.role) && (
              <React.Fragment>
                <DialogContentText>
                  Tipo do Usuário
                </DialogContentText>
                <Select
                  style={{width: '100%'}}
                  onChange={event => setRole(event.target.value)}
                  value={role}
                >
                  <MenuItem value={typeDataConstants.ROLE.COMMON}>
                    Comum
                  </MenuItem>
                  <MenuItem value={typeDataConstants.ROLE.ADMIN}>
                    Administrador
                  </MenuItem>
                  <MenuItem value={typeDataConstants.ROLE.MASTER}>
                    Master
                  </MenuItem>
                </Select>
              </React.Fragment>
            )}

            {[typeDataConstants.ROLE.MASTER].includes(userLogged.role) && (
              <TextField
                style={{width: '100%', marginLeft: 0}}
                id="requestsLimits"
                autoComplete="off"
                label="Número Máximo de Consultas"
                className={classes.textField}
                value={requestsLimit}
                onChange={(e) => setRequestsLimit(e.target.value)}
                type="number"
                error={(requestsLimit <= 0)}
                helperText={requestsLimit <= 0 ? 'O valor mínimo de consultas é igual a 1.' : null}
                required={true}
              />
            )}

            {[typeDataConstants.ROLE.MASTER, typeDataConstants.ROLE.ADMIN].includes(userLogged.role) && (
              _.get(organizationConfigData, 'template.vehicle_auction') && (
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={_.get(templateConfigUser, 'vehicle_auction')}
                      onChange={(e) => handleChangeTemplateConfigUser(e, 'vehicle_auction')}
                      name="checkedB"
                      color="primary"
                    />
                  )}
                  label="Pode consultar leilão"
                />
              )
            )}
          </React.Fragment>
        )}
      />
    )
  }

  const renderForm = () => {
    return (
      <React.Fragment>
        <TitleBold className={classes.titleNormal}>Cadastrar Usuário</TitleBold>

        <TextField
          id="name"
          label="Nome"
          className={classes.textField}
          value={name}
          onChange={(e) => setName(e.target.value)}
          error={!!(name.length !== 0 && name.length < 2)}
          required={true}
        />

        <TextField
          id="email"
          label="E-mail"
          className={classes.textField}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="email"
          required={true}
        />

        <TextField
          id="requestsLimits"
          autoComplete="off"
          label="Número Máximo de Consultas"
          className={classes.textField}
          value={requestsLimit}
          onChange={(e) => setRequestsLimit(e.target.value)}
          type="number"
          error={(requestsLimit < 10)}
          helperText={requestsLimit < 10 ? 'O valor mínimo de consultas é 10.' : null}
          required={true}
        />

        <h3 className={classes.labelSelect}>Tipo de Usuário</h3>
        <Select
          onChange={event => setRole(event.target.value)}
          value={role}
        >
          <MenuItem value={typeDataConstants.ROLE.COMMON}>
            Comum
          </MenuItem>
          <MenuItem value={typeDataConstants.ROLE.ADMIN}>
            Administrador
          </MenuItem>
        </Select>

        <div className={classes.buttonContainer}>
          <Button
            style={{marginTop: 20}}
            variant="contained"
            size="small"
            className={`${classes.button} ${classes.buttonRegister}`}
            onClick={handleSubmit}
          >
            Cadastrar
          </Button>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Card className={classes.paper}>
              <div className={classes.rowContainer}>
                <TitleBold>Painel Administrativo</TitleBold>
                {renderMenu()}
              </div>
            </Card>
            {/* <TemplateConfig /> */}
          </Grid>

          <Grid item xs={12} md={9}>
            <Card className={classes.paper}>
              <div className={classes.rowContainer}>
                {listUser && (
                  <React.Fragment>
                    <TitleBold className={classes.marginTitle}>Usuários da Organização</TitleBold>
                    {(userLogged.role === typeDataConstants.ROLE.MASTER) && renderMasterList()}
                    {renderList()}
                  </React.Fragment>
                )}
                {newUser && renderForm()}
                {report && <Report />}

                {renderRemoveModal()}
                {renderBlockModal()}
                {editUser && renderEditModal()}
                {error ? (
                  <p className={classes.error}>
                    <strong>
                      Erro ao cadastrar o usuário. Certifique-se de que inseriu os dados corretamente
                      e que não forneceu um email já utilizado por outro usuário.
                    </strong>
                  </p>
                 ) : null}
              </div>
            </Card>
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export default AdmPanel
