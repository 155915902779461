import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { selectApiKey, selectUserId, selectAccessToken} from 'redux/auth/saga.selectors'
import {
  getUserConfig,
} from 'services/apiMain'
import { s3PresignerUrl } from 'helpers/functions'

import * as listUsersActions from 'redux/listUsers/actions'
import * as actions from './actions'


function* generateLogoUrlAssigned(logoUrl) {
  return yield call(s3PresignerUrl, logoUrl)
}

function* fetchUserConfig() {
  yield takeEvery(actions.USER_CONFIG_FETCH, function* sg() {
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()
    const accessToken = yield selectAccessToken()

    let res = null
    try {
      res = yield call(getUserConfig, apiKey, accessToken, userId)
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter os dados'
      toast.error(messageError)
      yield put({ type: actions.USER_CONFIG_FETCH_FAILED, payload: messageError })
      return
    }

    const payload = res.data
    _.set(
      payload,
      'organization.logo_url_assigned',
      yield generateLogoUrlAssigned(_.get(payload, 'organization.logo_url')),
    )
    _.set(
      payload,
      'organization.report_logo_url_assigned',
      yield generateLogoUrlAssigned(_.get(payload, 'organization.report_logo_url')),
    )

    yield put({ type: actions.USER_CONFIG_FETCH_SUCCEEDED, payload: payload })
    yield put(listUsersActions.setUserHistoryShow(payload))
    yield put(actions.setTemplateUser(payload.template))
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchUserConfig),
  ])
}
