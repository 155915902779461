import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { selectApiKey, selectAccessToken } from 'redux/auth/saga.selectors'
import { selectUserConfig } from 'redux/userConfig/saga.selectors'
import {
  getAdminOrganization,
} from 'services/apiMain'

import * as actions from './actions'

function* fetchOrganizationConfig() {
  yield takeEvery(actions.ORGANIZATION_CONFIG_FETCH, function* sg(action) {
    let { organizationId } = action.payload

    const userLogged = yield selectUserConfig()
    if (_.isEmpty(organizationId)) organizationId = userLogged.data.organization.organization_id

    const apiKey = yield selectApiKey()
    const accessToken = yield selectAccessToken()

    let res = null
    try {
      res = yield call(getAdminOrganization, apiKey, accessToken, organizationId)
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter os dados da organização'
      toast.error(messageError)
      yield put({ type: actions.ORGANIZATION_CONFIG_FETCH_FAILED, payload: messageError })
      return
    }

    yield put({ type: actions.ORGANIZATION_CONFIG_FETCH_SUCCEEDED, payload: res.data })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchOrganizationConfig),
  ])
}
