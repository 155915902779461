import _ from 'lodash'

import {
    LIST_ORGANIZATION_CLEAR,
    FETCH_LIST_ORGANIZATIONS,
    FETCH_LIST_ORGANIZATIONS_SUCCEEDED,
    FETCH_LIST_ORGANIZATIONS_FAILED,
} from './actions'

const initialState = {
  organizations: [],
  fail: false,
  load: false,
  messageError: '',
}

export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ORGANIZATION_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case FETCH_LIST_ORGANIZATIONS: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case FETCH_LIST_ORGANIZATIONS_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case FETCH_LIST_ORGANIZATIONS_SUCCEEDED: {
      return {
        ...state,
        organizations: action.payload.list,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    default:
      return state
  }
}

export default reportReducer