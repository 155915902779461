import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  cardItemData: {
    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        padding: 0,
      },
    },
  },
  listItems: {
    [theme.breakpoints.down('xs')]: {
      '@media not print': {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },
  containerListItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  paper: {
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
  },
  header: {
    paddingTop: 0,
  },
  headerText: {
    fontSize: '20px',
  },
  containerItemData: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItemPagination: {
  },
  containerInputsFilter: {
    marginBottom: 10,
    paddingLeft: 16,
  },
  inputFilterText: {
     width: '30%',
  },
}))

export default styles
