import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

export default makeStyles(theme => ({
  root: {
    width: '100%',
  },
  appbar: {
    backgroundPosition: 'initial',
    backgroundSize: 'cover',

    [theme.breakpoints.down('sm')]: {
      '@media not print': {
        padding: 10,
      },
    },
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '@media(max-width: 1170px)': {
      padding: 10,
      flexWrap: 'wrap',
    },
  },
  sectionLogo: {
    display: 'flex',
    marginRight: '4rem',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      marginBottom: 10,
    },
  },
  logoContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  logoImg: {
    height: 50,
  },
  menubar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      flexWrap: 'wrap',
      width: '100%',
    },
  },
  sectionUser: {
    display: 'flex',
    minHeight: 'inherit',
    '@media(max-width: 1170px)': {
      marginTop: 30,
      paddingTop: 15,
      borderTop: '1px solid #CECECE',
    },
  },
  listNav: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media(max-width: 1170px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: 240,

    },
  },
  listNavItem: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    transition: 'all 0.2s',
    '&:hover': {
      // backgroundColor: theme.palette.primary.hover,
      cursor: 'pointer',
    },

    '@media(max-width: 1170px)': {
      padding: 10,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        backgroundColor: '#cecece',
      },
    },
  },
  listNavItemUsername: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    '@media(max-width: 1170px)': {
      padding: 10,
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      width: '100%',
      '&:hover': {
        backgroundColor: '#cecece',
      },
    },
  },
  listNavItemSep: {
    marginLeft: 10,
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: '#ffffff',
    marginRight: 10,
    marginLeft: 5,
    '&:hover': {
      backgroundColor: 'none',
    },
    '@media(max-width: 1170px)': {
      color: '#333',
    },
  },
  logolf: {
    display: 'none',
    '@media(max-width: 1170px)': {
      color: '#333',
      display: 'flex',
      alignItems: 'center',
      textDecoration: 'none',
      marginRight: 10,
      marginLeft: 5,
    },
  },
  dropdown: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.lighter,
  },
  sectionDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  version: {
    bottom: 2,
    color: '#ccc',
    position: 'absolute',
    visibility: 'hidden',
  },
  containerTitle: {
    marginLeft: 30,
  },
  title: {
     fontWeight: 'bold',
  },
}))
