import _ from 'lodash'

import {
  FETCH_LIST_ITEMS,
  FETCH_LIST_ITEMS_FAILED,
  FETCH_LIST_ITEMS_SUCCEEDED,
  LIST_ITEMS_CLEAR,
} from './actions'

const initialState = {
  currentPage: 1,
  fail: false,
  items: [],
  load: false,
  messageError: '',
  perPage: 0,
  total: 0,
}

export const listItemsReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_ITEMS_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case FETCH_LIST_ITEMS: {
      return {
        ...state,
        fail: false,
        load: true,
      }
    }
    case FETCH_LIST_ITEMS_SUCCEEDED: {
      return {
        ...state,
        fail: false,
        load: false,
        currentPage: action.payload.currentPage,
        items: action.payload.items,
        perPage: action.payload.perPage,
        total: action.payload.total,
      }
    }
    case FETCH_LIST_ITEMS_FAILED: {
      return {
        ..._.cloneDeep(initialState),
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }

    default:
      return state
  }
}

export default listItemsReducer
