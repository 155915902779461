import { combineReducers } from 'redux'
import { authReducer } from './auth/auth.reducer'
import { listItemsReducer } from './listItems/reducer'
import { dataRequestedReducer } from './dataRequested/reducer'
import { userConfigReducer } from './userConfig/reducer'
import { organizationConfigReducer } from './organizationConfig/reducer'
import { listUserReducer } from './listUsers/reducer'
import { reportReducer} from './report/reducer'

export const Reducers = combineReducers({
  auth: authReducer,
  listItems: listItemsReducer,
  dataRequested: dataRequestedReducer,
  userConfig: userConfigReducer,
  organizationConfig: organizationConfigReducer,
  listUsers: listUserReducer,
  report: reportReducer,
})
