import red from '@material-ui/core/colors/red'
import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    padding: 20,
    background: theme.palette.primary.bg,
    height: '100%',
  },
  paper:{
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
    marginBottom: 10,
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    backgroundColor: theme.palette.third.main,
    fontSize: 20,
    color: '#FFF',
    border: 'none',
    width: '100%',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#2962ff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '200px',
    },
  },
  firstLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  userName:{
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  userEmail:{
    fontSize: 16,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  buttonEdit:{
    fontFamily: 'inherit',
    fontSize: 20,
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    fontWeight: 'bold',
    color: theme.palette.primary.hover,
    marginRight: 10,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  buttonRemove:{
    fontFamily: 'inherit',
    fontSize: 20,
    border: 'none',
    backgroundColor: 'inherit',
    cursor: 'pointer',
    color: red.A400,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
  textField: {
    width: '90%',
    marginBottom: 15,
    marginTop: 15,
    marginLeft: 15,

    [theme.breakpoints.down('sm')]: {
      width: 'initial',
      marginLeft: 0,
    },
  },
  selectOrg:{
    width: '50%',
    marginLeft: 16,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  labelSelect:{
    marginTop: 15,
    marginLeft: 15,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  title:{
    marginLeft: 16,
     [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  error: {
    color: 'red',
    fontSize: 16,
    marginTop: 16,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      textAlign: 'justify',
      padding: '0 10px',
    },
  },
   cardOptions: {
    marginTop: 16,
  },
  buttonRegister: {
    width: '60%',
    backgroundColor: '#2979ff',
  },
  buttonGreen: {
    color: '#FFF',
    width: 100,
    backgroundColor: '#00c853',
    fontSize: '0.90rem',
    '&:hover': {
      backgroundColor: '#00e676',
    },
  },
  buttonRed: {
    backgroundColor: '#e53935',
    color: '#FFF',
    width: 100,
    fontSize: '0.90rem',
    '&:hover': {
      backgroundColor: '#ef5350',
    },
  },
  lineButtons: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  buttonsParagraph: {
    marginTop: 15,
  },
  numberRequests: {
    fontWeight: 'bold',
    fontSize: 25,
    marginLeft: 5,
  },
  marginTitle: {
    marginTop: 16,
    marginLeft: 16,
  },
  expasionPanel: {
    display: 'block',
  },
  spanRequests: {
    [theme.breakpoints.down('sm')]: {
      marginTop: 5,
      fontSize: 14,
    },
  },
  errorUsers: {
    fontSize: 17,
    fontWeight: 'bold',
    color: '#f44336',
    marginLeft: 16,
    marginTop: 16,

    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      textAlign: 'center',
    },
  },
}))

export default styles
