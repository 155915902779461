export const USER_CONFIG_CLEAR = 'USER_CONFIG_CLEAR'

export const USER_CONFIG_FETCH = 'USER_CONFIG_FETCH'
export const USER_CONFIG_FETCH_FAILED = 'USER_CONFIG_FETCH_FAILED'
export const USER_CONFIG_FETCH_SUCCEEDED = 'USER_CONFIG_FETCH_SUCCEEDED'

export const SET_TEMPLATE_USER = 'SET_TEMPLATE_USER'
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE'
export const ATT_USER_CONFIG = 'ATT_USER_CONFIG' 


export const userConfigClear = () => ({
  type: USER_CONFIG_CLEAR,
})

export const fetchUserConfig = () => ({
  type: USER_CONFIG_FETCH,
})

export const setTemplateUser = (template) => ({
  type: SET_TEMPLATE_USER,
  payload: template,
})

export const setCurrentPage = (page) => ({
  type: SET_CURRENT_PAGE,
  payload: page,
})

export const attUserConfig = (user) => ({
  type: ATT_USER_CONFIG,
  payload: {
    user: user,
  },
})