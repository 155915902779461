import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')
  const dataFipePricings = _.get(dataObj, 'fipe_pricing')
  const dataMolicarPricings = _.get(dataObj, 'molicar_pricing')

  // TODO: melhorar layout

  const renderFipeValue = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Modelo:</span>
          <span className={classes.value}>{_.get(data, 'model')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Marca:</span>
          <span className={classes.value}>{_.get(data, 'brand')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Combustível:</span>
          <span className={classes.value}>{_.get(data, 'fuel')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>valor:</span>
          <span className={classes.value}>{_.get(data, 'value')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Código:</span>
          <span className={classes.value}>{_.get(data, 'code')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Data de referência da tabela FIPE:</span>
          <span className={classes.value}>{_.get(data, 'fipe_reference_date')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Valor zero quilômetro:</span>
          <span className={classes.value}>{_.get(data, 'brand_new_value')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  const renderMolicarValue = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Modelo:</span>
          <span className={classes.value}>{_.get(data, 'model')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Marca:</span>
          <span className={classes.value}>{_.get(data, 'brand')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Combustível:</span>
          <span className={classes.value}>{_.get(data, 'fuel')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>valor:</span>
          <span className={classes.value}>{_.get(data, 'value')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Versão:</span>
          <span className={classes.value}>{_.get(data, 'version')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Precificação</h1>

      <h2>Fipe</h2>
      {_.map(dataFipePricings, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderFipeValue(item)}
        </React.Fragment>
      ))}

      <h2>Molicar</h2>
      {_.map(dataMolicarPricings, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderMolicarValue(item)}
        </React.Fragment>
      ))}

    </div>
  )
}
