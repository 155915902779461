import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { selectApiKey, selectAccessToken } from 'redux/auth/saga.selectors'
import { getReport } from 'services/apiMain'

import * as actions from './actions'

function* fetchListItems() {
  yield takeEvery(actions.FETCH_LIST_ITEMS, function* sg(action) {
    const { options } = action.payload

    const apiKey = yield selectApiKey()
    const accessToken = yield selectAccessToken()

    const userId = (!_.isEmpty(options.user_id)) ? options.user_id : null

    let res = null
    try {
      res = yield call(getReport, apiKey, accessToken, userId, options)
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter a configuração'
      toast.error(messageError)
      yield put({ type: actions.FETCH_LIST_ITEMS_FAILED, payload: messageError })
      return
    }

    const payload = {
      items: res.data,
      total: parseInt(res.headers['x-total'], 10),
      currentPage: parseInt(res.headers['x-page'], 10),
      perPage: parseInt(res.headers['x-per-page'], 10),
    }

    yield put({ type: actions.FETCH_LIST_ITEMS_SUCCEEDED, payload: payload })
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchListItems),
  ])
}
