import React from 'react'
import _ from 'lodash'

// import PanelVehicleRegistration from './PanelVehicleRegistration' // TODO: Apagar
import PanelVehicleBinState from './PanelVehicleBinState'
import PanelVehicleDecoder from './PanelVehicleDecoder'
import PanelVehiclePricing from './PanelVehiclePricing'
import PanelVehicleRecall from './PanelVehicleRecall'
import PanelVehicleAuction from './PanelVehicleAuction'
import PanelVehicleTheft from './PanelVehicleTheft'

import styles from './styles'

export default ({ consultData }) => {
  const classes = styles()

  const termType = _.get(consultData, 'term_type')
  const serviceInfos = _.get(consultData, 'service_infos') || []

  // const vehicleRegistrationData = _.find(serviceInfos, { service_id: `vehicle_registration_by_${termType}` })
  const vehicleBinFederalData = _.find(serviceInfos, { service_id: `vehicle_bin_federal_by_${termType}` })
  const vehicleBinStateData = _.find(serviceInfos, { service_id: `vehicle_bin_state_by_${termType}` })
  const vehiclePricingData = _.find(serviceInfos, { service_id: `vehicle_pricing_by_${termType}` })
  const vehicleDecoderData = _.find(serviceInfos, { service_id: `vehicle_decoder_by_${termType}` })
  const vehicleRecallData = _.find(serviceInfos, { service_id: `vehicle_recall_by_${termType}` })
  const vehicleAuctionData = _.find(serviceInfos, { service_id: `vehicle_auction_by_${termType}` })
  const vehicleTheftData = _.find(serviceInfos, { service_id: `vehicle_theft_by_${termType}` })

  return (
    <div className={classes.container}>
      {/* <PanelVehicleRegistration serviceObj={vehicleRegistrationData} /> */}
      {/* <div className={classes.dividerSpace} /> */}

      <PanelVehicleBinState serviceObj={vehicleBinStateData} />
      <div className={classes.dividerSpace} />

      <PanelVehicleDecoder serviceObj={vehicleDecoderData} />
      <div className={classes.dividerSpace} />

      <PanelVehiclePricing serviceObj={vehiclePricingData} />
      <div className={classes.dividerSpace} />

      <PanelVehicleRecall serviceObj={vehicleRecallData} />
      <div className={classes.dividerSpace} />

      <PanelVehicleTheft serviceObj={vehicleTheftData} />
      <div className={classes.dividerSpace} />

      <PanelVehicleAuction serviceObj={vehicleAuctionData} />
      <div className={classes.dividerSpace} />
    </div>
  )
}
