import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { selectApiKey, selectAccessToken} from 'redux/auth/saga.selectors'
import { getReportConsumerAdmin } from 'services/apiMain'
import * as actions from './actions'

function* fetchListOrganizations(){
  yield takeEvery(actions.FETCH_LIST_ORGANIZATIONS, function* sg(action){
    const { organization_id, filter } = action.payload

    const apiKey = yield selectApiKey()
    const token = yield selectAccessToken()

    let res = null
    try{
        res = yield call(getReportConsumerAdmin, apiKey, token, organization_id, filter)
    } catch (err){
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter a lista de usuários'
      toast.error(messageError)
      yield put({type: actions.FETCH_LIST_ORGANIZATIONS_FAILED, payload: messageError })
      return
    }

    const payload = {
        list: res.data,
    }

    yield put({ type: actions.FETCH_LIST_ORGANIZATIONS_SUCCEEDED, payload: payload})
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchListOrganizations),
  ])
}
