import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')
  const dataDetails = _.get(dataObj, 'details')

  const renderDetails = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Risco:</span>
          <span className={classes.value}>{_.get(data, 'risk')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Descrição:</span>
          <span className={classes.value}>{_.get(data, 'complete_description')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Defeito:</span>
          <span className={classes.value}>{_.get(data, 'defect')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Data do início:</span>
          <span className={classes.value}>{_.get(data, 'campaign_initial_date')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Código do Procon:</span>
          <span className={classes.value}>{_.get(data, 'procon_code')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Gravidade:</span>
          <span className={classes.value}>{_.get(data, 'severity')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Confirmação pela montadora:</span>
          <span className={classes.value}>{_.get(data, 'subject_to_automaker_confirmation')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Telefone de confirmação:</span>
          <span className={classes.value}>{_.get(data, 'confirmation_phone')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Recall</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Tem recall:</span>
          <span className={classes.value}>{_.get(dataObj, 'response_description')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <h2>Detalhes</h2>
      {_.map(dataDetails, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderDetails(item)}
        </React.Fragment>
      ))}

    </div>
  )
}
