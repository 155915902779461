export const LIST_ITEMS_CLEAR = 'LIST_ITEMS_CLEAR'

export const FETCH_LIST_ITEMS = 'FETCH_LIST_ITEMS'
export const FETCH_LIST_ITEMS_FAILED = 'FETCH_LIST_ITEMS_FAILED'
export const FETCH_LIST_ITEMS_SUCCEEDED = 'FETCH_LIST_ITEMS_SUCCEEDED'

export const listItemsClear = () => ({
  type: LIST_ITEMS_CLEAR,
})

export const fetchListItems = (options = {}) => ({
  type: FETCH_LIST_ITEMS,
  payload: { options: options },
})
