import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import compose from 'recompose/compose'
import MainNavbar from 'components/MainNavbar'
// import Sidebar from 'components/Sidebar'
// import jwtDecode from 'jwt-decode'

const PrivateRoute = ({ component: Component, auth, ...rest }) => {
  const { access_token } = auth

  const isAuth = () => {
    if (access_token) {
      // const decoded = jwtDecode(access_token)
      // const currentTime = Date.now() / 1000
      // if (decoded.exp < currentTime) {
      //   return false
      // }
      return true
    }
    if (access_token === null) {
      return false
    }
    return false
  }

  return (
    <Route
      {...rest}
      render={props =>
        isAuth() ? (
          <div>
            {/* <Sidebar /> */}
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <MainNavbar />
              <div>
                <Component {...props} />
              </div>
            </div>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

const mapStateToProps = store => ({
  auth: store.auth,
})

export default compose(connect(mapStateToProps, null))(PrivateRoute)