import { all, call, cancel, delay, fork, put, take, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import typeDataConstants from 'helpers/constants/typeData'

import { selectApiKey, selectAccessToken } from 'redux/auth/saga.selectors'
import { selectUserConfig } from 'redux/userConfig/saga.selectors'
import {
  getChassis,
  getLicensePlate,
  patchChassis,
  patchChassisUploadPhoto,
  patchLicensePlate,
  patchLicensePlateUploadPhoto,
  postChassisCreate,
  postLicensePlateCreate,
  deleteLicensePlatePhoto,
  deleteChassisPhoto,
  patchLicensePlateClientData,
  patchChassisClientData,
} from 'services/apiMain'
import { generateToken, s3PresignerUrl } from 'helpers/functions'
import * as actionsListItems from 'redux/listItems/actions'

import {
  selectUserShowId,
} from 'redux/listUsers/saga.selectors'

import {
 incrementRequestsCount,
} from 'redux/listUsers/actions'


import {
  selectData,
  selectMessageError,
  selectTerm,
  selectTokenSelected,
  selectType,
} from './saga.selectors'

import * as actions from './actions'

// TODO: gerar url assinada apenas das que não tiverem
function* addPhotoUrlAssigned(vehiclePhotos) {
  const newVehiclePhotos = _.cloneDeep(vehiclePhotos)
  for (let i = 0; i < newVehiclePhotos.length; i += 1) {
    const photo = newVehiclePhotos[i]
    photo.image_url_assigned = yield call(s3PresignerUrl, photo.image_url)
  }

  return newVehiclePhotos
}

function* postData() {
  yield takeEvery(actions.POST_DATA, function* sg() {
    const apiKey = yield selectApiKey()
    const accessToken = yield selectAccessToken()
    const userShowId = yield selectUserShowId()
    const userLogged = yield selectUserConfig()
    const term = yield selectTerm()
    const type = yield selectType()

    const userId = (userLogged.data.user_id !== userShowId) ? userShowId : null
    const token = generateToken()
    try {
      if (type === typeDataConstants.licensePlate) {
       yield call(postLicensePlateCreate, apiKey, accessToken, userId, term, token)
     } else {
        yield call(postChassisCreate, apiKey, accessToken, userId, term, token)
     }
    } catch (err) {
      console.error(err)
      Sentry.captureException(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar os dados'
      yield put({ type: actions.POST_DATA_FAILED, payload: messageError })
      toast.error(messageError)
      return
    }

    yield put({ type: actions.POST_DATA_SUCCEEDED, payload: token })
    yield put(actionsListItems.fetchListItems())
    if (userLogged.data.role !== 'common') yield put(incrementRequestsCount())

    yield put({ type: actions.STOP_BACKGROUND_CHECK_DATA_STATUS })
    yield put({ type: actions.START_BACKGROUND_CHECK_DATA_STATUS })
  })
}

function* fetchData() {
  yield takeEvery(actions.FETCH_DATA, function* sg(action) {
    const { token, type } = action.payload

    const apiKey = yield selectApiKey()
    let userId = yield selectUserShowId()
    const accessToken = yield selectAccessToken()
    const userLogged = yield selectUserConfig()

    if(userId === userLogged.data.user_id ) userId = null

    let res = null
    try {
      if (type === typeDataConstants.licensePlate) {
        res = yield call(getLicensePlate, apiKey, accessToken, userId, token)
      } else {
        res = yield call(getChassis, apiKey, accessToken, userId, token)
      }
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Não foi possível obter os dados.'
      toast.error(messageError)
      yield put({ type: actions.FETCH_DATA_FAILED, payload: messageError })
      return
    }

    if (!_.isEmpty(_.get(res.data, 'msg_infos'))) {
      const messageError = _.get(res.data, 'msg_infos.0.msg') || 'Não foi possível obter os dados.'
      yield put({ type: actions.FETCH_DATA_FAILED, payload: messageError })
      return
    }

    const payload = res.data

    if (res.status === 200 && payload.vehicle_photos) {
      payload.vehicle_photos = yield addPhotoUrlAssigned(payload.vehicle_photos)
    }

    yield put({ type: actions.FETCH_DATA_SUCCEEDED, payload: payload })
  })
}

function* patchData() {
  yield takeEvery(actions.PATCH_DATA, function* sg(action) {
    const { params } = action.payload

    const apiKey = yield selectApiKey()
    let userId = yield selectUserShowId()
    const accessToken = yield selectAccessToken()
    const userLogged = yield selectUserConfig()
    const type = yield selectType()
    const token = yield  selectTokenSelected()

    if(userId === userLogged.data.user_id ) userId = null

    let res = null
    try {
      if (type === typeDataConstants.licensePlate) {
        res = yield call(patchLicensePlate, apiKey, accessToken, userId, token, params)
      } else {
        res = yield call(patchChassis, apiKey, accessToken, userId, token, params)
      }
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar os dados'
      toast.error(messageError)
      yield put({ type: actions.PATCH_DATA_FAILED, payload: messageError })
      return
    }

    const payload = res.data

    if(payload.vehicle_photos)
      payload.vehicle_photos = yield addPhotoUrlAssigned(payload.vehicle_photos)

    yield put({ type: actions.PATCH_DATA_SUCCEEDED, payload: payload })
  })
}

function* patchClientData() {
  yield takeEvery(actions.PATCH_CLIENT_DATA, function* sg(action) {
    const { params } = action.payload

    const apiKey = yield selectApiKey()
    let userId = yield selectUserShowId()
    const accessToken = yield selectAccessToken()
    const type = yield selectType()
    const token = yield  selectTokenSelected()
    const userLogged = yield selectUserConfig()

    if(userId === userLogged.data.user_id ) userId = null

    let res = null
    try {
      if (type === typeDataConstants.licensePlate) {
        res = yield call(patchLicensePlateClientData, apiKey, accessToken, userId, token, params)
      } else {
        res = yield call(patchChassisClientData, apiKey, accessToken, userId, token, params)
      }
    } catch (err) {
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao enviar os dados'
      toast.error(messageError)
      yield put({ type: actions.PATCH_CLIENT_DATA_FAILED, payload: messageError })
      return
    }

    const payload = res.data

    if(payload.vehicle_photos)
      payload.vehicle_photos = yield addPhotoUrlAssigned(payload.vehicle_photos)

    yield put({ type: actions.PATCH_CLIENT_DATA_SUCCEEDED, payload: payload })
  })
}

function* uploadPhoto() {
  yield takeEvery(actions.DATA_UPLOAD_PHOTO, function* sg(action) {
    const { imageUrl } = action.payload

    const apiKey = yield selectApiKey()
    let userId = yield selectUserShowId()
    const accessToken = yield selectAccessToken()
    const type = yield selectType()
    const token = yield  selectTokenSelected()
    const userLogged = yield selectUserConfig()

    if(userId === userLogged.data.user_id ) userId = null

    let res = null
    try {
      if (type === typeDataConstants.licensePlate) {
        res = yield call(patchLicensePlateUploadPhoto, apiKey, accessToken, userId, token, imageUrl)
      } else {
        res = yield call(patchChassisUploadPhoto, apiKey, accessToken, userId, token, imageUrl)
      }
    } catch (err) {
      Sentry.captureException(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Não foi possivel salvar a imagem'
      toast.error(messageError)
      yield put({ type: actions.DATA_UPLOAD_PHOTO_FAILED, payload: messageError })
      return
    }

    const payload = res.data

    if(payload.vehicle_photos)
      payload.vehicle_photos = yield addPhotoUrlAssigned(payload.vehicle_photos)

    yield put({ type: actions.DATA_UPLOAD_PHOTO_SUCCEEDED, payload: payload })
  })
}

function* deletePhoto() {
  yield takeEvery(actions.DATA_DELETE_PHOTO, function* sg(action) {
    const { imageUrl } = action.payload

    const apiKey = yield selectApiKey()
    let userId = yield selectUserShowId()
    const accessToken = yield selectAccessToken()
    const type = yield selectType()
    const token = yield  selectTokenSelected()
    const userLogged = yield selectUserConfig()

    if(userId === userLogged.data.user_id ) userId = null

    try {
      if (type === typeDataConstants.licensePlate) {
        yield call(deleteLicensePlatePhoto, apiKey, accessToken, userId, token, imageUrl)
      } else {
        yield call(deleteChassisPhoto, apiKey, accessToken, userId, token, imageUrl)
      }
    } catch (err) {
      Sentry.captureException(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Não foi possivel salvar a imagem'
      toast.error(messageError)
      yield put({ type: actions.DATA_DELETE_PHOTO_FAILED, payload: messageError })
      return
    }

    yield put({ type: actions.DATA_DELETE_PHOTO_SUCCEEDED, payload: { imageUrl } })
  })
}

function* bgCheckStatusServices() {
  const tokenSelected = yield selectTokenSelected()
  const type = yield selectType()
  const userConfig = yield selectUserConfig()

  const adminId = (userConfig.data.role === typeDataConstants.ROLE.ADMIN) ? userConfig.data.user_id : null

  try {
    // TODO: pensar uma maneira melhor de fazer isto

    let counter = 0
    do {
      yield delay(counter * 800)

      yield put(actions.fetchData(tokenSelected, type, adminId))

      const actionRes = yield take([actions.FETCH_DATA_SUCCEEDED, actions.FETCH_DATA_FAILED])

      if (actionRes.type === actions.FETCH_DATA_FAILED) {
        const messageError = yield selectMessageError()
        toast.error(messageError)
        break
      }

      const dataReq = yield selectData()
      if (!_.isEmpty(dataReq)) break

      // if (counter >= 5) {
      //   yield put({ type: actions.FETCH_DATA_FAILED, payload: 'Não foi possível obter os dados.' })
      //   toast.error('Não foi possível obter os dados, tente novamente mais tarde.')
      //   break
      // }
      counter += 1
    } while (true)

    yield put({ type: actions.STOP_BACKGROUND_CHECK_DATA_STATUS })
  } finally {
    // if (yield cancelled()) yield put(actions.requestFailure('Sync cancelled!'))
  }
}

function* checkServicesStatus() {
  while ( yield take(actions.START_BACKGROUND_CHECK_DATA_STATUS) ) {
    const bgCheckStatus = yield fork(bgCheckStatusServices)
    yield take(actions.STOP_BACKGROUND_CHECK_DATA_STATUS)
    yield cancel(bgCheckStatus)
  }
}

export default function* rootSaga() {
  yield all([
    fork(checkServicesStatus),
    fork(deletePhoto),
    fork(fetchData),
    fork(patchData),
    fork(patchClientData),
    fork(postData),
    fork(uploadPhoto),
  ])
}
