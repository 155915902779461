import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  dividerSpace: {
    padding: 10,

    '@media print': {
      padding: 4,
    },
  },
}))

export default styles
