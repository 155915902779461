import React from 'react'
import styled from 'styled-components'
import _ from 'lodash'


export const TitleNormal = styled.h2`
  font-size: 20px;
  font-weight: normal;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
`
export const TitleBold = styled.h2`
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: condensed;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
`

const StyledInput = styled.input`
  width: 267px;
  height: 40px;
  padding: 10px;
  border-radius: 13px;
  border: solid 1px #cccccc;
  background-color: #FFF;
  font-size: 18px;
  font-family: 'Barlow Condensed', sans-serif;
`

export const Input = (props) => {
  return (
    <div style={{marginBottom: 20}}>
      <StyledInput {...props} autoComplete="off" />
      <div style={{marginLeft: 10}}>
        {
          _.isString(props.helperText)
            ? <p>{props.helperText}</p>
            : props.helperText.map((str, i) => <p key={i.toString()}>{str}</p>)
        }
      </div>
    </div>
  )
}
