import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify'

import { getListConsults } from 'services/apiMain'

import {
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemText,
  TablePagination,
} from '@material-ui/core'

import { MaskInput } from 'components/MaskedInput'
import styles from './styles'

const ListItems = ({ forceUpdate, onSelect }) => {
  const classes = styles()

  const apiKey = useSelector(state => state.auth.api_key)
  const userId = useSelector(state => state.auth.sub)

  const [consultItems, setConsultItems] = useState([])
  const [totalItems, setTotalItems] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage, setPerPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const [docIdSelected, setDocIdSelected] = useState(null)
  const [loading, setLoading] = React.useState(true)
  const [filterText, setFilterText] = useState('')

  const getListItems = async (options = {}) => {
    setLoading(true)

    let res = null
    try {
      res = await getListConsults(apiKey, userId, options)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter as consultas'
      toast.error(messageError)
      setLoading(false)
      return
    }

    const xTotal = parseInt(res.headers['x-total'], 10)
    setTotalItems(xTotal)
    setTotalPages(Math.ceil(xTotal / parseInt(res.headers['x-per-page'], 10)))
    setPerPage(parseInt(res.headers['x-per-page'], 10))
    setCurrentPage(parseInt(res.headers['x-page'], 10))
    setConsultItems(res.data.vehicle_datas)

    setLoading(false)
  }

  useEffect(() => {
    getListItems()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate])

  const callFilter = async (options) => {
    getListItems({
      license_plate: options.filterText, // TODO: mudar quando tiver a pesquisa geral
    })
  }
  const [debouncedCallFilter] = useState(() => _.debounce(callFilter, 800))

  const handleInputFilterText = (event) => {
    const term = event.target.value

    debouncedCallFilter({ filterText: term })
    setFilterText(term)
  }

  const handleSelectItem = async (docId) => {
    if (docId === docIdSelected) return

    setDocIdSelected(docId)
    if(onSelect) onSelect(docId)
  }

  const handleChangePageListItems = async (_event, page) => {
    if (totalItems === 0) return

    getListItems({
      page: page + 1,
      license_plate: filterText, // TODO: mudar quando tiver a pesquisa geral
    })
  }

  const renderListItems = (docId) => {
    const itemData = _.find(consultItems, ['doc_id', docId])

    return (
      <div className={classes.containerListItem}>
        <div style={{ width: '6.5rem', fontSize: '1.1rem' }}>
          {itemData.license_plate}
        </div>
        <span style={{ marginLeft: 30 }}>
          {moment(itemData.created_at).format('DD-MM-YYYY HH:mm')}
        </span>
      </div>
    )
  }

  const renderInputFilterText = () => (
    <MaskInput
      className={classes.inputFilterText}
      label="Buscar"
      name="none"
      onChange={handleInputFilterText}
      value={filterText}
    />
  )

  return (
    <Card className={classes.paper} style={{ marginTop: 20 }}>
      <CardHeader
        className={classes.header}
        title={<span className={classes.headerText}>Histórico</span>}
      />

      <div className={classes.containerInputsFilter}>
        {renderInputFilterText()}
      </div>

      <Divider />

      <CardContent classes={{ root: classes.cardItemData }}>
        <div className={classes.containerItemData}>
          {loading && (
            <CircularProgress style={{ marginTop: 20 }} />
          )}

          <div>
            <List>
              {consultItems.map((item, i) => (
                <ListItem
                  className={classes.listItems}
                  button
                  key={i.toString()}
                  disabled={item.doc_id === docIdSelected}
                  selected={item.doc_id === docIdSelected}
                  onClick={() => handleSelectItem(item.doc_id)}
                >
                  <ListItemText>{renderListItems(item.doc_id)}</ListItemText>
                </ListItem>
              ))}
            </List>

            <TablePagination
              className={classes.listItemPagination}
              component="nav"
              page={currentPage - 1}
              rowsPerPage={perPage}
              rowsPerPageOptions={[]}
              count={totalPages}
              onChangePage={handleChangePageListItems}
              labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
            />
          </div>

        </div>
      </CardContent>
    </Card>
  )
}

export default ListItems
