import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import {
  Grid,
} from '@material-ui/core'

import ListItems from 'components/ListItems'
import CardInput from 'components/CardInput'
import CardSummaryItem from 'components/CardSummaryItem'
import PanelConsult from 'components/PanelConsult'

import { getConsult } from 'services/apiMain'

// import typeData from 'helpers/constants/typeData'
import styles from './styles'

const Dashboard = props => {
  const classes = styles(props)

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)

  const [lastListItemsDate, setLastListItemsDate] = useState(null)
  const [consultData, setConsultData] = useState(null)

  const handleSubmitConsult = () => {
    setLastListItemsDate(new Date())
  }

  const handleDeselectConsult = () => {
    setConsultData(null)
  }

  const handleSelectConsult = async (docId) => {
    let res = null

    try {
      res = await getConsult(apiKey, userId, docId)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro selecionar consulta'
      toast.error(messageError)
      return
    }

    res.data.term_type = 'license_plate' // TODO: remover depois
    setConsultData(res.data)
  }

  return (
    <div className={classes.root}>
      <main className={classes.main}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={3}>
            {consultData && (
              <CardSummaryItem
                consultType={consultData.term_type}
                consultValue={consultData.term} // TODO: rever campo
                onClickClear={handleDeselectConsult}
              />
            )}

            <CardInput onClickSubmit={handleSubmitConsult} />

            <ListItems
              forceUpdate={lastListItemsDate}
              onSelect={handleSelectConsult}
            />
          </Grid>

          <Grid item xs={12} md={true} lg={true} className="container-to-print">
            <PanelConsult consultData={consultData} />
          </Grid>
        </Grid>
      </main>
    </div>
  )
}

export default Dashboard
