export const LIST_USER_CLEAR = 'LIST_USER_CLEAR'

export const FETCH_LIST_USERS = 'FETCH_LIST_USER'
export const FETCH_LIST_USERS_FAILED = 'FETCH_LIST_USER_FAILED'
export const FETCH_LIST_USERS_SUCCEEDED = 'FETCH_LIST_USER_SUCCEEDED'

export const SET_USER_HISTORY_SHOW = 'SET_USER_HISTORY_SHOW'
export const INCREMENT_REQUESTS_COUNT = 'INCREMENT_REQUESTS_COUNT' 
export const UPDATE_USER = 'UPDATE_USER'

export const listUsersClear = () => ({
  type: LIST_USER_CLEAR,
})

export const fetchListUsers = (payload) => ({
  type: FETCH_LIST_USERS,
  payload,
})

export const setUserHistoryShow = (user) => ({
  type: SET_USER_HISTORY_SHOW,
  payload: {
    user: user,
  },
})

export const incrementRequestsCount = () => ({
  type: INCREMENT_REQUESTS_COUNT,
})

export const updateUserData = (user) => ({
  type: UPDATE_USER,
  payload: {
    user: user,
  },
})