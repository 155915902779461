import React from 'react'
import _ from 'lodash'
import { useTheme } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
// import typeDataConstants from 'helpers/constants/typeData'

import {
  AppBar,
  Toolbar,
  Popper,
  Grow,
  Paper,
  Typography,
  // InputBase,
  MenuItem,
  MenuList,
  ClickAwayListener,
  useMediaQuery,
  IconButton,
  Drawer,
  Button,
} from '@material-ui/core'

import {
  Menu as MenuIcon,
  InsertEmoticon,
  ExitToApp as ExitToAppIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@material-ui/icons'
import { logout } from 'redux/auth/auth.actions'
import { setCurrentPage } from 'redux/userConfig/actions'

import styles from './styles'

const MainNavbar = props => {
  const classes = styles(props)
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null)
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()

  const valuesForLink = {
    'dashboard': {
      'textLinkToNextPage': 'Painel Administrativo',
      'nextPage': 'adm-panel',
    },
    'adm-panel': {
      'textLinkToNextPage': 'Dashboard',
      'nextPage': 'dashboard',
    },
  }

  // function handleToggle() {
  //   setOpen(prevOpen => !prevOpen)
  // }

  const name = useSelector(state => state.auth.name)
  const userConfig = useSelector(state => state.userConfig.data)
  const currentPage = useSelector(state => state.userConfig.currentPage)

  const [openDrawer, setOpenDrawer] = React.useState(false)

  const userOrganization = _.get(userConfig, 'organization')

  function handleDrawerOpen() {
    setOpenDrawer(true)
  }

  function handleDrawerClose() {
    setOpenDrawer(false)
  }

  function handleClose(event) {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return
    }

    setOpen(false)
  }

  const renderMenu = (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      keepMounted
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <Paper id="menu-list-grow">
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                classes={{
                  root: classes.dropdown,
                }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )

  const handleRedirect = () => {
    const { nextPage } = valuesForLink[currentPage]
    dispatch(setCurrentPage(nextPage))
    history.push(nextPage)
  }

  const menuItens = () => (
    <ul className={classes.listNav}>
      {(userConfig.role === 'admin' || userConfig.role === 'master') && (
      <li className={classes.listNavItem}>
        <Typography color="inherit">
          <Button className={classes.menuItem} onClick={handleRedirect} color="primary">
            {valuesForLink[currentPage].textLinkToNextPage}
          </Button>
        </Typography>
      </li>
      )}
      {/* <li className={classes.listNavItem}>
        <Assessment />
        <Typography color="inherit">
          <Link className={classes.menuItem} to="/">
            RELATÓRIO
          </Link>
        </Typography>
      </li>

      <li className={classes.listNavItem}>
        <CheckBox />
        <Typography color="inherit">
          <Link
            className={classes.menuItem}
            to="/containers_list"
          >
            LISTA
          </Link>
        </Typography>
      </li>

      <li className={classes.listNavItem}>
        <Warning />
        <Typography color="inherit">
          <Link
            className={classes.menuItem}
            to="/analyze_container"
          >
            STATUS
          </Link>
        </Typography>
      </li> */}
    </ul>
  )

  const sectionUser = () => (
    <div className={classes.sectionUser}>
      <ul className={classes.listNav}>
        <li className={classes.listNavItemUsername}>
          <InsertEmoticon />
          <Typography color="inherit" className={classes.menuItem}>{name.toUpperCase()}</Typography>
        </li>
        <li className={classes.listNavItem}>
          <ExitToAppIcon onClick={() => dispatch(logout())} />
          <Typography color="inherit" className={classes.logolf}>SAIR</Typography>
        </li>
      </ul>
    </div>
  )

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={classes.appbar}
        style={{ backgroundColor: _.get(userOrganization, 'navbar_color') }}
      >
        <Toolbar>
          <div
            className={classes.toolbar}
          >
            <div className={classes.sectionLogo}>
              {useMediaQuery('(max-width: 1170px)') && (
                <IconButton
                  color="inherit"
                  onClick={handleDrawerOpen}
                >
                  <MenuIcon />
                </IconButton>
              )}
              <div className={classes.logoContainer}>
                <img
                  className={classes.logoImg}
                  src={_.get(userOrganization, 'logo_url_assigned')}
                  alt="logo"
                />
                <div className={classes.containerTitle}>
                  <Typography
                    className={classes.title}
                    variant="h5"
                    color="inherit"
                    noWrap
                  >
                    Avaliação de Veículo
                  </Typography>
                  <span className={classes.version}>
                    {`version ${process.env.REACT_APP_PROJECT_VERSION}`}
                  </span>
                </div>
              </div>
            </div>

            <div
              className={classes.menubar}
            >
              <div className={classes.sectionDesktop}>
                {useMediaQuery('(min-width: 1170px)') && menuItens()}
              </div>

              {useMediaQuery('(min-width: 1170px)') && sectionUser()}

            </div>
          </div>
        </Toolbar>
        <Drawer
          className={classes.drawer}
          open={openDrawer}
          onClose={handleDrawerClose}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          {useMediaQuery('(max-width: 1170px)') && menuItens()}
          {useMediaQuery('(max-width: 1170px)') && sectionUser()}
        </Drawer>
      </AppBar>
      {renderMenu}
    </div>
  )
}

export default MainNavbar
