import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Decodificador</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Ano do Modelo:</span>
          <span className={classes.value}>{_.get(dataObj, 'model_year')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Categoria:</span>
          <span className={classes.value}>{_.get(dataObj, 'category')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Chassi:</span>
          <span className={classes.value}>{_.get(dataObj, 'chassi')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Localidade do fabricante:</span>
          <span className={classes.value}>{_.get(dataObj, 'manufacture_location')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Marca:</span>
          <span className={classes.value}>{_.get(dataObj, 'brand')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Modelo:</span>
          <span className={classes.value}>{_.get(dataObj, 'model')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Origem:</span>
          <span className={classes.value}>{_.get(dataObj, 'origin')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Pais de origem:</span>
          <span className={classes.value}>{_.get(dataObj, 'country')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Região de abrangência:</span>
          <span className={classes.value}>{_.get(dataObj, 'region')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Tipo de carroceria:</span>
          <span className={classes.value}>{_.get(dataObj, 'body_type')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Versão:</span>
          <span className={classes.value}>{_.get(dataObj, 'version')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

    </div>
  )
}
