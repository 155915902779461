import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import { toast } from 'react-toastify'

import {
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'

import { MaskInput } from 'components/MaskedInput'
import typeData from 'helpers/constants/typeData'
import { licensePlateValid, chassiValid } from 'helpers/validators'
import { postCreate } from 'services/apiMain'

import styles from './styles'

export default ({ onClickSubmit }) => {
  const classes = styles()

  const apiKey = useSelector((state) => state.auth.api_key)
  const userId = useSelector((state) => state.auth.sub)

  const [consultValue, setConsultValue] = useState('')
  const [consultType, setConsultType] = useState(typeData.licensePlate)
  const [consultValueValid, setConsultValueValid] = useState(false)

  const resetInputs = () => {
    setConsultValue('')
    setConsultType(typeData.licensePlate)
    setConsultValueValid(false)
  }

  const handleChange = (_name = null) => event => {
    const val = event.target.value.toUpperCase()
    setConsultValue(val)

    const valid = consultType === typeData.licensePlate
      ? licensePlateValid(val)
      : chassiValid(val)

    setConsultValueValid(valid)
  }

  const handleChangeType = (event) => {
    setConsultType(event.target.value)
  }

  const handleClickPost = async () => {
    try {
      await postCreate(apiKey, userId, consultValue, consultType)
    } catch (err) {
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao criar a consulta'
      toast.error(messageError)
      return
    }

    resetInputs()
    toast.success('Consulta criada com sucesso!')
    if (onClickSubmit) onClickSubmit()
  }

  const renderRadioGroupType = () => {
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">Buscar veículo por:</FormLabel>
        <RadioGroup
          aria-label="buscar por"
          value={consultType}
          onChange={handleChangeType}
          row={true}
        >
          <FormControlLabel value={typeData.licensePlate} control={<Radio />} label="Placa" />
          <FormControlLabel value={typeData.chassis} control={<Radio />} label="Chassi" />
        </RadioGroup>
      </FormControl>
    )
  }

  return (
    <Card className={classes.paper}>
      <div className={classes.rowContainer}>
        {renderRadioGroupType()}

        <MaskInput
          className={classes.textField}
          label={`${consultType === typeData.licensePlate ? 'Placa' : 'Chassi'} do Veículo`}
          onChange={handleChange()}
          // name="none"
          variant="outlined"
          value={consultValue}
          errorMsg={consultValueValid ? null : "Valor Inválido"}
        />

        <Button
          variant="contained"
          size="small"
          className={classes.button}
          onClick={handleClickPost}
          disabled={!consultValueValid}
        >
          Solicitar
        </Button>
      </div>
    </Card>
  )
}
