import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.third.main,
    fontSize: 20,
    color: '#FFF',
    border: 'none',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: theme.palette.third.hover,
    },
  },
  paper: {
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
  },
  rowContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  textField: {
    width: '100%',
    marginBottom: 30,
  },
}))

export default styles
