import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')
  const dataHistory = _.get(dataObj, 'history')

  const renderHistory = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Boletim:</span>
          <span className={classes.value}>{_.get(data, 'report')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Código do órgão de segurança:</span>
          <span className={classes.value}>{_.get(data, 'security_agency_code')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Documento informante:</span>
          <span className={classes.value}>{_.get(data, 'informing_document')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Informante:</span>
          <span className={classes.value}>{_.get(data, 'informant')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Ramal telefone de contato:</span>
          <span className={classes.value}>{_.get(data, 'contact_phone_extension')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Telefone de contato:</span>
          <span className={classes.value}>{_.get(data, 'contact_phone')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Número do documento:</span>
          <span className={classes.value}>{_.get(data, 'document_number')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>UF de ocorrência:</span>
          <span className={classes.value}>{_.get(data, 'occurrence_uf')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Ocorrência:</span>
          <span className={classes.value}>{_.get(data, 'occurrence')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Declaração:</span>
          <span className={classes.value}>{_.get(data, 'declaration')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Cidade de ocorrência:</span>
          <span className={classes.value}>{_.get(data, 'occurrence_city')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Data da ocorrência:</span>
          <span className={classes.value}>{_.get(data, 'occurrence_date')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Roubo</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Risco:</span>
          <span className={classes.value}>{_.get(dataObj, 'consists_occurrence')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Descrição:</span>
          <span className={classes.value}>{_.get(dataObj, 'consists_active_occurrence')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Defeito:</span>
          <span className={classes.value}>{_.get(dataObj, 'origin_indicator')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Risco:</span>
          <span className={classes.value}>{_.get(dataObj, 'license_plate_municipality')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <h2>Registros</h2>
      {_.map(dataHistory, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderHistory(item)}
        </React.Fragment>
      ))}

    </div>
  )
}
