import _ from 'lodash'

import typeDataConstants from 'helpers/constants/typeData'
import { chassiValid, licensePlateValid } from 'helpers/validators'

import {
  DATA_REQUESTED_CLEAR,
  DATA_SET_TYPE,
  DATA_SET_TERM,
  FETCH_DATA,
  FETCH_DATA_FAILED,
  FETCH_DATA_SUCCEEDED,
  PATCH_DATA,
  PATCH_DATA_FAILED,
  PATCH_DATA_SUCCEEDED,
  PATCH_CLIENT_DATA,
  PATCH_CLIENT_DATA_FAILED,
  PATCH_CLIENT_DATA_SUCCEEDED,
  DATA_UPLOAD_PHOTO_FAILED,
  DATA_UPLOAD_PHOTO_SUCCEEDED,
  DATA_DELETE_PHOTO_FAILED,
  DATA_DELETE_PHOTO_SUCCEEDED,
  POST_DATA,
  POST_DATA_FAILED,
  POST_DATA_SUCCEEDED,
  SELECT_ITEM,
} from './actions'

const initialState = {
  data: {},
  fail: false,
  load: false,
  messageError: '',
  showing: false,
  tokenSelected: null,
  type: typeDataConstants.licensePlate,
  term: '',
  termValid: false,
}

export const dataRequestedReducer = (state = initialState, action) => {
  switch (action.type) {
    case DATA_REQUESTED_CLEAR: {
      return _.cloneDeep(initialState)
    }

    case DATA_SET_TYPE: {
      const type = action.payload
      const valid = type === typeDataConstants.licensePlate ? licensePlateValid(state.term) : chassiValid(state.term)

      return {
        ...state,
        type: type,
        termValid: valid,
      }
    }

    case DATA_SET_TERM: {
      const term = action.payload
      const valid = state.type === typeDataConstants.licensePlate ? licensePlateValid(term) : chassiValid(term)

      return {
        ...state,
        term: term,
        termValid: valid,
      }
    }

    case SELECT_ITEM: {
      const { token, term, type } = action.payload

      return {
        ...state,
        fail: false,
        load: true,
        tokenSelected: token,
        term: term,
        type: type,
        termValid: true,
        showing: true,
      }
    }

    case POST_DATA: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
        showing: true,
      }
    }
    case POST_DATA_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
        showing: false,
        termValid: false,
      }
    }
    case POST_DATA_SUCCEEDED: {
      return {
        ...state,
        tokenSelected: action.payload,
        fail: false,
        load: false,
        messageError: '',
        showing: true,
      }
    }

    case FETCH_DATA: {
      return {
        ...state,
        tokenSelected: action.payload.token,
        type: action.payload.type,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case FETCH_DATA_FAILED: {
      return {
        ...initialState,
        fail: true,
        messageError: action.payload,
      }
    }
    case FETCH_DATA_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    case PATCH_DATA: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case PATCH_DATA_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case PATCH_DATA_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    case PATCH_CLIENT_DATA: {
      return {
        ...state,
        load: true,
        fail: false,
        messageError: '',
      }
    }
    case PATCH_CLIENT_DATA_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case PATCH_CLIENT_DATA_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    case DATA_UPLOAD_PHOTO_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case DATA_UPLOAD_PHOTO_SUCCEEDED: {
      return {
        ...state,
        data: action.payload,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    case DATA_DELETE_PHOTO_FAILED: {
      return {
        ...state,
        fail: true,
        load: false,
        messageError: action.payload,
      }
    }
    case DATA_DELETE_PHOTO_SUCCEEDED: {
      const newData = _.cloneDeep(state.data)
      newData.vehicle_photos = _.filter(newData.vehicle_photos, (photos) => {
        return photos.image_url !== action.payload.imageUrl
      })

      return {
        ...state,
        data: newData,
        fail: false,
        load: false,
        messageError: '',
      }
    }

    default:
      return state
  }
}

export default dataRequestedReducer
