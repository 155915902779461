import React from 'react'
import _ from 'lodash'
import styles from './styles'

export default ({ serviceObj }) => {
  const classes = styles()

  const dataObj = _.get(serviceObj, 'data')
  const dataScore = _.get(dataObj, 'score')
  const dataRecords = _.get(dataObj, 'records')

  const renderRecords = (data) => (
    <>
      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Comitente:</span>
          <span className={classes.value}>{_.get(data, 'principal')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Condição geral:</span>
          <span className={classes.value}>{_.get(data, 'general_condition')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Cor:</span>
          <span className={classes.value}>{_.get(data, 'color')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Data do leilão:</span>
          <span className={classes.value}>{_.get(data, 'auction_date')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>leiloeiro:</span>
          <span className={classes.value}>{_.get(data, 'auctioneer')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentText}>
          <span className={classes.description}>Número de lote:</span>
          <span className={classes.value}>{_.get(data, 'batch_number')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Observações:</span>
          <span className={classes.value}>{_.get(data, 'notes')}</span>
        </div>
      </div>

      <hr className={classes.hl} />
    </>
  )

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Leilão</h1>

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Descrição:</span>
          <span className={classes.value}>{_.get(dataObj, 'description')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Risco:</span>
          <span className={classes.value}>{_.get(dataScore, 'acceptance')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Descrição:</span>
          <span className={classes.value}>{_.get(dataScore, 'special_inspection_requirement')}</span>
        </div>

        <span className={classes.vl} />

        <div className={classes.containerContentTextSmall}>
          <span className={classes.description}>Defeito:</span>
          <span className={classes.value}>{_.get(dataScore, 'percentage_over_ref_price')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <div className={classes.containerLine}>
        <div className={classes.containerContentText}>
          <span className={classes.description}>Risco:</span>
          <span className={classes.value}>{_.get(dataScore, 'score')}</span>
        </div>
      </div>

      <hr className={classes.hl} />

      <h2>Registros</h2>
      {_.map(dataRecords, (item, i) => (
        <React.Fragment key={i.toString()}>
          {renderRecords(item)}
        </React.Fragment>
      ))}

    </div>
  )
}
