import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'
import PropTypes from 'prop-types'
import {
  TextField,
} from '@material-ui/core'

import {
  cepMask,
  cpfMask,
  dateMask,
  phoneMask,
  cnpjMask,
} from './Masks'
import './styles.css'

const getRawValue = (regArray, value) => {
  if (!(regArray instanceof Array)) return value

  const regexStr = regArray.reduce((result, curr) => {
    if (curr instanceof RegExp) {
      const regStr = curr.toString().replace(/^\/|\/$/g, '')
      return result + `((${regStr})|.)`
    }

    return result + `\\${curr}`
  }, '')

  const regComp = new RegExp(regexStr)

  const valueArray = regComp.exec(value)
  const rawValue = (valueArray instanceof Array) ? valueArray.slice(1).filter((v, i) => i % 2).join('') : null

  return rawValue
}

const mask = (name) => {
  if (name === 'cpf') return cpfMask
  if (name === 'cnpj') return cnpjMask
  if (name === 'phone_number') return phoneMask
  if (name === 'cep') return cepMask
  if (name === 'date') return dateMask
  return false
}

function TextMaskCustom(props) {
  const { inputRef, ...other } = props

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null)
      }}
      mask={mask(props.name)}
      placeholderChar="_"
      onChange={(e) => props.onChange(e, getRawValue(mask(props.name), e.target.value))}
    />
  )
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
}

export function MaskInput({ name, onChange, value, label, variant, className, errorMsg }) {

  const [wasBlurred, setWasBlurred] = useState(false)

  return (
    <TextField
      value={value}
      onChange={onChange}
      InputProps={name && {
        inputComponent: TextMaskCustom,
      }}
      name={name}
      label={label}
      variant={variant}
      className={className}
      autoComplete="off"
      error={!!errorMsg && wasBlurred && !!value}
      helperText={wasBlurred && !!value ? errorMsg : null}
      onBlur={() => setWasBlurred(true)}
    />
  )
}
