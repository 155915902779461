import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

import { fetchListOrganizations, listOrganizationClear } from 'redux/report/actions'

import {
  Card,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  // ExpansionPanel,
  // ExpansionPanelDetails,
  // ExpansionPanelSummary,
  // Typography,
  Grid,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core'

import { toast } from 'react-toastify'
import { TitleBold, TitleNormal } from 'components/styledComponents'
// import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { KeyboardDatePicker } from "@material-ui/pickers"
import { verifyToken } from 'helpers/functions'

import styles from './styles'

const Report = props => {
  const classes = styles(props)

  const dispatch = useDispatch()
  const history = useHistory()

  const userLogged = useSelector(state => state.userConfig.data)
  const reportLoad = useSelector(state => state.report.load)
  const reportList = useSelector(state => state.report.organizations)
  const accessToken = useSelector(state => state.auth.access_token)
  const sub = useSelector(state => state.auth.sub)
  const refreshtoken = useSelector(state => state.auth.refresh_token)

  const [startDate, setStartDate] = React.useState((new Date()).setMonth((new Date()).getMonth() - 1))
  const [endDate, setEndDate] = React.useState(new Date())
  const [optionSelected, setOptionSelected] = React.useState(userLogged.role === 'master' ? 'initialOption' : userLogged.organization.organization_id)
  const generateFilter = () => {
    return {startDate: new Date(startDate).toLocaleDateString("pt-BR"), endDate: new Date(endDate).toLocaleDateString("pt-BR")}
  }

  const getReport = async (organizationId) => {
    await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
    const options = {filter: generateFilter()}
    options.organization_id = organizationId

    dispatch(fetchListOrganizations(options))
  }

  useEffect(() => {
    dispatch(listOrganizationClear())
    if(userLogged.role === 'admin') getReport(userLogged.organization.organization_id)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, userLogged.role, userLogged.organization.organization_id])

  const verifyInterval = () => {
    if(startDate > endDate){
      toast.error('A data inicial deve ser menor ou igual a final.')
      return false
    }
    if(endDate > Date.now()){
      toast.error('O intervalo não pode terminar depois da data atual.')
      return false
    }
    return true
  }

  const handleFilter = async () => {
    if(optionSelected === 'initialOption' && userLogged.role === 'master'){
      toast.error('Selecione uma organização antes de aplicar o filtro.')
      return
    }

    if(verifyInterval()){
      await verifyToken(accessToken, history, dispatch, sub, refreshtoken)
      const filter = generateFilter()
      const orgId = optionSelected
      dispatch(listOrganizationClear())
      dispatch(fetchListOrganizations({ organization_id: orgId, filter }))
    }
  }

  const handleSelectReport = async (event) => {
    const { value } = event.target
    setOptionSelected(value)

    if(value === 'initialOption' ){
      dispatch(listOrganizationClear())
      return
    }

    const options = {filter: generateFilter()}
    options.organization_id = value

    dispatch(listOrganizationClear())
    dispatch(fetchListOrganizations(options))
  }

  const renderDatePickers = () => {
    return (
      <Grid container className={classes.grid}>
        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          label="Data inicial"
          format="dd/MM/yyyy"
          value={startDate}
          InputAdornmentProps={{ position: "start" }}
          onChange={date => setStartDate(date)}
        />

        <KeyboardDatePicker
          autoOk
          variant="inline"
          inputVariant="outlined"
          label="Data final"
          format="dd/MM/yyyy"
          value={endDate}
          InputAdornmentProps={{ position: "start" }}
          onChange={date => setEndDate(date)}
          className={classes.endDate}
        />

        <Button onClick={handleFilter} className={classes.button} variant="contained">Filtrar</Button>
      </Grid>
    )
  }

  const renderUsers = (users) => {
    return (
      <List style={{marginTop: 10}}>
        {users.map(user => (
          <Card key={user.user_id} className={classes.paper}>
            <ListItem>
              <ListItemText>
                <p className={classes.firstLineItem}>
                  <span className={classes.userName}>
                    {' '}
                    {`${user.name} (${user.role})`}
                  </span>

                  <span className={classes.requestsContainerUser}>
                    <span style={{color: '#43a047'}} className={classes.mLeft}>
                      {user.simple_requests}
                      {' '}
                      simples
                    </span>

                    <span style={{color: '#43a047'}} className={classes.mLeft}>
                      {user.auction_requests}
                      {' '}
                      com leilão
                    </span>

                    <span style={{color: '#43a047'}} className={classes.mLeft}>
                      {user.auction_detailed_requests}
                      {' '}
                      com leilão detalhado
                    </span>

                    <span style={{color: '#fbc02d'}} className={classes.mLeft}>
                      {user.processing_requests}
                      {' '}
                      em processamento
                    </span>

                    <span style={{color: '#e53935'}} className={classes.mLeft}>
                      {user.failed_requests}
                      {' '}
                      falharam
                    </span>
                  </span>
                </p>
              </ListItemText>
            </ListItem>
          </Card>
        ))}
      </List>
    )
  }

  const renderRequestsContainer = (org) => {
    return (
      <div className={classes.requestsContainer}>
        <TitleNormal className={classes.requestsTotal}>
          <span className={classes.requestsSuccess}>
            {org.total_simple_requests}
          </span>
          {'  '}
          simples
        </TitleNormal>

        <TitleNormal className={classes.requestsTotal}>
          <span className={classes.requestsAuction}>
            {org.total_auctions_requests}
          </span>
          {'  '}
          com leilão
        </TitleNormal>

        <TitleNormal className={classes.requestsTotal}>
          <span className={classes.requestsAuctionDetails}>
            {org.total_auction_detailed_requests}
          </span>
          {'  '}
          com leilão detalhado
        </TitleNormal>

        <TitleNormal className={classes.requestsTotal}>
          <span className={classes.requestsProcessing}>
            {org.total_processing_requests}
          </span>
          {'  '}
          em processamento
        </TitleNormal>

        <TitleNormal className={classes.requestsTotal}>
          <span className={classes.requestsFail}>
            {org.total_failed_requests}
          </span>
          {'  '}
          falharam
        </TitleNormal>
      </div>
    )
  }

  const renderOrganizationReport = (org) => {
    if (optionSelected === 'initialOption') return null
    if (reportLoad || _.isEmpty(org)) return <CircularProgress style={{ marginTop: 10 }} />

    return (
      <React.Fragment>
        <TitleBold className={classes.title}>
          {org.name}
        </TitleBold>

        {renderDatePickers()}

        <TitleNormal className={classes.reportType}>
          {`Relatório do período: ${new Date(startDate).toLocaleDateString("pt-BR")} - ${new Date(endDate).toLocaleDateString("pt-BR")}`}
        </TitleNormal>

        {renderRequestsContainer(org)}
        {renderUsers(org.users)}
      </React.Fragment>
    )
  }

  const renderMasterReport = () => {
    return (
      <React.Fragment>
        <TitleBold className={classes.title} style={{marginBottom: 16}}>
          Organizações Gerenciadas
        </TitleBold>

        <Select
          className={classes.selectOrg}
          onChange={handleSelectReport}
          value={optionSelected}
        >
          <MenuItem key="initialOption" value="initialOption">
            Selecione uma organização...
          </MenuItem>

          {
            !userLogged.managed_organizations
            ? <CircularProgress style={{marginTop: 16, marginLeft: 16}} />
            : userLogged.managed_organizations.map(item => (
              <MenuItem key={item.name} value={item.organization_id}>
                {item.name}
              </MenuItem>
            ))
          }
        </Select>

        {renderOrganizationReport(reportList[0])}
      </React.Fragment>
    )
  }

  const renderReport = () => {
    if(userLogged.role === 'master'){
      return renderMasterReport()
    }
    return renderOrganizationReport(reportList[0])
  }

  return renderReport()
}

export default Report
