import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  main: {
    padding: 20,
    background: theme.palette.primary.bg,
    height: '100%',
  },
}))

export default styles
