import { all } from 'redux-saga/effects'
import dataRequestedSagas from './dataRequested/sagas'
import listItemsSagas from './listItems/sagas'
import userConfigSagas from './userConfig/sagas'
import organizationConfigSagas from './organizationConfig/sagas'
import listUsersSagas from './listUsers/sagas'
import reportSagas from './report/sagas'

export default function* rootSaga(getState) {
  yield all([
    dataRequestedSagas(),
    listItemsSagas(),
    userConfigSagas(),
    organizationConfigSagas(),
    listUsersSagas(),
    reportSagas(),
  ])
}
