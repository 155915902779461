import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import _ from 'lodash'
import classnames from 'classnames'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'
import Recaptcha from 'react-recaptcha'
import { TextField, Button, CircularProgress, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/styles'

import { successLogin, changePassword } from 'redux/auth/auth.actions'
import { login } from 'services/apiAuth'
import { isNetworkError, isUserLogged } from 'helpers/functions'

import styles from './styles'

const Login = () => {
  const classes = styles()
  const dispatch = useDispatch()
  const history = useHistory()

  const accessToken = useSelector(state => state.auth.access_token)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [isVerified, setIsVerified] = useState(false)
  const [recaptchaToken, setRecaptchaToken] = useState(null)

  useEffect(() => {
    if (isUserLogged(accessToken)) {
      history.push('/dashboard')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const handleSubmit = async (argEmail, argPassword) => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append('username', argEmail)
      formData.append('password', argPassword)
      formData.append('app_id', process.env.REACT_APP_COGNITO_APP_ID)
      formData.append('user_pool', process.env.REACT_APP_COGNITO_USER_POOL)
      formData.append('recaptcha_token', recaptchaToken)

      const res = await login(formData)
      if (res.status === 302) {
        dispatch(changePassword({
          email: argEmail,
          changePasswordSession: res.data.session,
        }))

        return history.push('/password-change')
      }

      const userData = res.data
      if (userData) {
        dispatch(successLogin({ ...userData }))
      }

      return false
    } catch (err) {
      Sentry.captureException(err)
      setLoading(false)
      setIsVerified(false)
      setRecaptchaToken(null)

      const status = _.get(err, 'response.status') || err.status
      const statusMsg = status ? `${status}: ` : ''
      const messageError = isNetworkError(err)
        ? `${statusMsg}Problema na solicitação: ${err.message}`
        : `${statusMsg}${_.get(err, 'response.data.msg_errors.0.msg')}`

      return toast.error(messageError)
    }
  }

  const onEnterPress = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      return handleSubmit(email, password)
    }
    return false
  }

  const verifyCaptcha = response => {
    if (response) {
      setIsVerified(true)
      setRecaptchaToken(response)
    }
  }

  return (
    <div className={classes.root}>
      <aside className={classes.aside}>
        <h1>
          <img src="assets/images/logos/inova_white.svg" alt="Logo" className={classes.logo} />
        </h1>
      </aside>
      <main className={classes.main}>
        <div className={classes.main}>
          <h1 className={classes.title}>Login</h1>
          <div className={classes.inputsContainer}>
            <form className={classes.formContainer}>
              <TextField
                id="email"
                label="E-mail"
                className={classes.textField}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                required={true}
                autoCorrect="off"
                autoCapitalize="off"
              />
              <TextField
                id="password"
                label="Senha"
                className={classes.textField}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={onEnterPress}
                autoComplete="off"
                type="password"
                required={true}
                autoCorrect="off"
                autoCapitalize="off"
              />
            </form>
            <Typography>
              <Link to="/password-recover">Esqueci a senha</Link>
            </Typography>
          </div>

          <div className={classes.buttonContainer}>
            <div className={isVerified ? classes.noDisplay : ''}>
              <Recaptcha
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                render="explicit"
                expiredCallback={() => {}}
                onloadCallback={() => {}}
                verifyCallback={verifyCaptcha}
                hl="pt-BR"
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              className={classnames(classes.button, !isVerified ? classes.noDisplay : '')}
              onClick={() => handleSubmit(email, password)}
              disabled={!!loading}
            >
              {
                loading
                ? <CircularProgress className={classes.progress} />
                : 'Acessar'
              }
            </Button>
          </div>
        </div>
      </main>
    </div>
  )
}


export default withStyles(styles, { withTheme: true })(Login)
