import red from '@material-ui/core/colors/red'
import { createMuiTheme } from '@material-ui/core/styles'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#bc2953',
      hover: '#ba103d',
      contrast: '#ffffff',
      bg: '#fff',
      dark: '#192131',
      darker: '#070f20',
      light: '#9b9fb0',
      lighter: '#fff',

      orange: '#ed9900',
      gray: '#808080',
      grayDark: '#666666',
      grayLight: '#cccccc',
      grayLight2: '#999999',
      grayPaper: '#f8f8f8',

      black: '#000000',
      green: '#19D315',
      greenHover: '#019101',
      red: '#E91010',
    },
    secondary: {
      main: '#343a40',
      hover: '#61656b',
    },
    third: {
      main: '#19d315',
      hover: '#019101',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
    },
  },
   typography: {
    fontFamily: "'Barlow Condensed', sans-serif",
    // htmlFontSize: 10,
    // fontSize: 14,
   },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  report: {
     fontSize: 18,
     fontSizeInPrint: 12,
     fontSizeTitle: 14,
     marginFieldInPrint: 0,
  },
})

export default theme
