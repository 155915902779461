import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import _ from 'lodash'
import * as Sentry from '@sentry/browser'
import { toast } from 'react-toastify'

import { selectApiKey, selectUserId, selectAccessToken } from 'redux/auth/saga.selectors'
import { listItemsClear } from 'redux/listItems/actions'
import { getUsersList } from 'services/apiMain'

import * as actions from './actions'

const sortListUsers = (users, userId) => {
  const userLogged = _.remove(users, (user) =>  user.user_id === userId)
  const sorteredUsers = _.sortBy(users, (user) => user.name.toLowerCase())
  return _.concat(userLogged, sorteredUsers)
}

function* fetchListUsers(){
  yield takeEvery(actions.FETCH_LIST_USERS, function* sg(action){
    const organization_id = _.get(action, 'payload.organization_id') || null
    const apiKey = yield selectApiKey()
    const userId = yield selectUserId()
    const accessToken = yield selectAccessToken()

    let res = null
    try{
      res = yield call(getUsersList, apiKey, accessToken, organization_id)
    } catch (err){
      Sentry.captureException(err)
      console.error(err)
      const messageError = _.get(err, 'response.data.msg_errors.0.msg') || 'Erro ao obter a lista de usuários'
      toast.error(messageError)
      yield put({ type: actions.FETCH_LIST_USERS_FAILED, payload: messageError })
      return
    }

    const userList = sortListUsers(res.data, userId)
    const payload = {
      list: userList,
    }

    yield put({ type: actions.FETCH_LIST_USERS_SUCCEEDED, payload: payload})
    if(userList[0])
      yield put(actions.setUserHistoryShow(userList[0]))
    else{
      yield put(listItemsClear())
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchListUsers),
  ])
}
