export const LIST_ORGANIZATION_CLEAR = 'LIST_ORGANIZATION_CLEAR'

export const FETCH_LIST_ORGANIZATIONS = 'FETCH_LIST_ORGANIZATION'
export const FETCH_LIST_ORGANIZATIONS_FAILED = 'FETCH_LIST_ORGANIZATION_FAILED'
export const FETCH_LIST_ORGANIZATIONS_SUCCEEDED = 'FETCH_LIST_ORGANIZATION_SUCCEEDED'

export const listOrganizationClear = () => ({
  type: LIST_ORGANIZATION_CLEAR,
})

export const fetchListOrganizations = (payload) => ({
  type: FETCH_LIST_ORGANIZATIONS,
  payload,
})