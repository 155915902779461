import axios from './axiosCustom'

const SERVER = process.env.REACT_APP_API_FILES_DOWNLOADER_URL
const AUTH = process.env.REACT_APP_API_FILES_DOWNLOADER_AUTH
const ROTA = '/v1'

const api = axios.create({
  baseURL: SERVER,
})

api.interceptors.request.use(async config => {
  const newConfig = config

  newConfig.headers['x-api-key'] = AUTH

  return newConfig
})

const reqS3PresignerUrl = fileUrl =>
  api.get(`${SERVER}${ROTA}/s3/presigner/url?file_url=${fileUrl}`)

export default api
export { reqS3PresignerUrl }
