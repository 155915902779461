import _ from 'lodash'
import jwtDecode from 'jwt-decode'

import { reqS3PresignerUrl } from 'services/apiFilesDownloader'
import { renewToken } from 'services/apiAuth'
import { refreshToken } from 'redux/auth/auth.actions'

export const generateToken = () => ((Math.random() * 10**20).toString())

export const s3PresignerUrlOpen = async doc_url => {
  if (!doc_url) return

  try {
    const response = await reqS3PresignerUrl(doc_url)

    window.open(response.data.url, "_blank")
  } catch (err) {
    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }
  }
}

export const s3PresignerUrl = async (doc_url) => {
  if (!doc_url) return null

  try {
    const res = await reqS3PresignerUrl(doc_url)

    return res.data.url
  } catch (err) {
    if (err.response) {
      console.error(err.response)
    } else if (err.request) {
      console.error(err.request)
    } else {
      console.error(err.message)
    }

    return null
  }
}

export const sleep = m => new Promise(r => setTimeout(r, m))

export const isUserLogged = token => {
  if (_.isEmpty(token)) return false

  const decoded = jwtDecode(token)
  const currentTime = Date.now() / 1000
  return decoded.exp > currentTime
}

export const isPasswordValid = (value) => {
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
  return regex.test(value)
}

export const onlyNumbers = (string) => {
  return _.isString(string) ? string.replace(/[^\d]+/g, '') : ''
}

export const isNetworkError = (err) => {
  return !!err.isAxiosError && !err.response
}

export const isAuth = (access_token) => {
  if (access_token) {
    const decoded = jwtDecode(access_token)
    const currentTime = Date.now() / 1000 // Convertendo o timestamp pra timestamp unix

    if (decoded.exp < currentTime) { // Token espirado
      if((currentTime - decoded.exp) > 14400)
        return {'status': 'expired', 'action': 'login'} // Token expirado a mais de 4 horas, necessário refazer o login

      return {'status': 'expired', 'action': 'renew'} // Token expirado mas ainda com possibilidade de renovação
    }

    return {'status': 'ok'} // Token válido
  }

  return {'status': 'invalid'} // Token recebido inválido
}

export const verifyToken = async (accessToken, history, dispatch, sub, refreshtoken) => {
  const auth = isAuth(accessToken)

  if(auth.status === 'ok'){
    return
  }

  if(auth.action === 'login'){
    history.push('/')
  }
  else{
    const res = await renewToken(sub, refreshtoken)
    dispatch(refreshToken(res.data))
  }
}

export const objToQueryString = (objQueryString) => {
  return _
    .chain(objQueryString)
    .pickBy((query) => !(_.isNil(query) || query === ''))
    .map((v, k) => `${k}=${v}`)
    .join('&')
    .value()
}
