import { makeStyles } from '@material-ui/core/styles'

const styles = makeStyles(theme => ({
  paper:{
    padding: 15,
    borderRadius: 15,
    border: 'solid 1px #cccccc',
    backgroundColor: '#F8F8F8',
    marginBottom: 10,
  },
  firstLineItem: {
    display: 'flex',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  userName:{
    fontSize: 18,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
    },
  },
  title:{
    marginTop: 16,
    fontSize: 30,
    marginLeft: 16,
     [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  numberRequests: {
    fontWeight: 'bold',
    fontSize: 30,
    marginLeft: 5,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  marginTitle: {
    marginTop: 16,
  },
  expasionPanel: {
    display: 'block',
  },
  reportType: {
    marginTop: 25,
    marginLeft: 16,
    fontWeight: 'bold',
    fontSize: 25,
     [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      fontSize: 17,
    },
  },
  requestsTotal: {
    marginLeft: 16,
    marginTop: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
  button: {
    marginLeft: 16,
    backgroundColor: '#2979ff',
    color: '#FFF',
    width: '10%',
    transistion: '0.2 all',
    '&:hover': {
      backgroundColor: '#448aff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '30%',
      marginLeft: 0,
    },
  },
  endDate: {
    marginTop: 0,
    marginBottom: 0,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      marginTop: 16,
      marginBottom: 16,
    },
  },
  grid: {
    marginTop: 25,
    marginLeft: 16,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  selectOrg: {
    marginLeft: 16,
    marginTop: 16,
    fontSize: 18,
    width: '50%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      width: '90%',
    },
  },
  requestsSuccess: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#43a047',
  },
  requestsAuction: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#43a047',
  },
  requestsAuctionDetails: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#43a047',
  },
  requestsFail: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#e53935',
  },
  requestsProcessing: {
    fontWeight: 'bold',
    fontSize: 30,
    color: '#fbc02d',
  },
  requestsContainer:{
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  requestsContainerUser:{
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  searchIcon: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  mLeft:{
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}))

export default styles
